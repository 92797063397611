//
// Account pages
// --------------------------------------------------

.login-content-block {
	.island;
	.island--highlighted;

	// Children
	// --------------------------
	// .actions {
	// 	//
	// }
}
