//
// Product Detail specific styles
// --------------------------------------------------

// Heading
// ---------------------------------------
// .prodcut-detail-page-heading {
// 	// font-size: 2.8rem;
// 	// letter-spacing: -0.0125em;
// 	// color: @secondary-site-color;
// }

// Byline
// --------------------------
.byline {
	margin-top:   -(@common-gutter / 2);
	margin-bottom: 1em;

	font-size: 1em;

	@media (min-width: @screen-md-min) {
		font-size: 1.1em;
	}
}



// Description
// ---------------------------------------
.product-description-inner {
	margin-bottom: @common-gutter;

	.product-description-copy {
		// max-width: 48em;
		padding-bottom: 0.01em;
		margin-bottom: 1em;
	}
}



// Product Utility Social Links
// ---------------------------------------
.product-utility-social-links {
	position: relative;
	z-index: 3;

	padding: (@common-gutter / 2) (@common-gutter);
	border-top: 1px solid @line-divider-color;
	margin: 0 -(@common-gutter);

	a {
		.transition (none);
	}
}

.product-utility-links-list {
	list-style: none;
	margin: 0;
}
.product-utility-links-li {
	display: inline-block;
	margin-right:  0.5em;
	margin-bottom: 0.5em;

	@media (min-width: @screen-lg-min) {
		margin-bottom: 0.1em;
	}
}
.product-utility-link {
	overflow: hidden; // kinda hacky way to hide text label's overflow

	font-size: 0.80em;
}

.social-share-buttons {
	//
	iframe {
		margin: 0;
	}
}
.product-social-share-button {
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	margin-right: 5px;
	margin-bottom: 5px;

	@media (min-width: @screen-lg-min) {
		width: 100%;
	}
}


// Photos
// ---------------------------------------
.product-detail-photos {
	margin-bottom: @common-gutter;
}


// Key Photo
// --------------------------
.product-detail-key-photo-wrapper {
	> figure {
		margin-bottom: 0;
	}
	.ez-wrapper {
		margin-bottom: 0;
	}
	.ez-nav-wrapper {
		margin-top: (@common-gutter);
	}

	@media (min-width: @desktop-styles-min) {
		.ez-nav-wrapper {
			margin-bottom: 0;
		}
	}
}

.product-detail-key-photo {
	display: block;
	margin-bottom: @common-gutter;

	// Prevent default image hover opacity change
	> img {
		opacity: 1 !important;
	}
}

// Caption
// --------------------------
.ez-caption {
	// padding-bottom: (@common-gutter / 2);
	// border-bottom: 1px solid @line-divider-color;

	padding-left:  0;
	padding-right: 0;
	margin-top: (@common-gutter / 2);
	// margin-bottom: -(@common-gutter / 2);

	&:empty {
		display: none;
	}

	@media (min-width: @desktop-styles-min) {
		padding-left:  5%;
		padding-right: 5%;

		text-align: center;
	}
}

// Thumbnails
// --------------------------
// .product-detail-photo-thumbnails-wrapper {}

.product-detail-photo-thumbnails {
	margin-left:  -(@common-gutter / 2);
	margin-right: -(@common-gutter / 2);
	padding: 0;

	text-align: center;
}
.product-detail-photo-thumbnail-column {
	padding-left:  (@common-gutter / 2);
	padding-right: (@common-gutter / 2);

	// change float method so they can be centered with 2/3 items
	float: none;
	display: inline-block;
	vertical-align: top;
	margin-right: -.25em;
}
.product-detail-photo-thumbnail-anchor {
	display: block;
	border: 1px solid transparent;

	&.active,
	.active > & {
		border-color: @link-color;
	}
}
.product-detail-photo-thumbnail {
	display: block;
	max-width: 100%;
	height: auto;
}



// Product Quantity Input
// ------------------------------------
.product-quantity-input {
	// Padding "reset" of sorts for these
	padding: 0.25em 0.5em 0.125em;
}




.variation-form {

	//
	.product-price {
		//
		// .discounted-price {
		// 	// display: block;
		// 	// margin-bottom: 0;
		// }
		.discount--original-price {
			font-size: 0.75em;
		}
	}

	//
	.product-actions-block {
		margin-bottom: 1em;

		text-align: center;
		clear: both;
	}

	.add-to-cart-button {
		// float: right;

		padding-left: 1em;
		padding-right: 1em;

		font-weight: bold;
		text-transform: uppercase;

	}

	// Media queries
	// --------------------------
	@media (min-width: @screen-lg-min) {
		.product-price {
			//
			.discounted-price {
				display: block;
			}
		}
	}
	@media (min-width: @screen-xl-min) {
		.add-to-cart-button {
			font-size: 1.1em;
		}
	}
}
