//
// Layout - Main (<main> element & main containers)
// --------------------------------------------------

// Main Content Inner
// ---------------------------------------
.global-main {
	position: relative;
	// z-index: 1;

	padding-bottom: 0.01px; // Prevents last item's margin from collapsing, allowing this element's background-color to be honored. Another (more drastic) solution is to set `overflow: hidden;`.
	margin-top: @header-mobile-height; // compensate for the fixed navigation layered on top of it

	background-color: @main-content-bg-color;

	// Generated element for "fading out"/shading content when a submenu is open
	&::after {
		content: " ";

		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		pointer-events: none;

		background: transparent;
		.transition(@main-content-fade-transition);
	}
}

.main-content-container {
	padding-top:    @content-vertical-spacing;
	padding-bottom: 0; //@content-vertical-spacing;

	@media (min-width: @site-max-width) {
		padding-top:    @content-vertical-spacing-locked;
		padding-bottom: 0; //@content-vertical-spacing-locked;
	}
	@media (min-width: (@site-max-width + @grid-gutter-width)) {
		padding-left: 0;
		padding-right: 0;
	}
}


.main-content-row {
	.display-flex();
	.flex-wrap();

	// Place the sidebar _after_ the main content for mobile
	.global-sidebar-navigation-wrapper {
		order: 2;

		margin-top: @common-gutter;
	}

	@media (min-width: @screen-md-min) {
		.global-sidebar-navigation-wrapper {
			order: 0;

			margin-top: 0;
		}
	}
}



// Sidebar
// ---------------------------------------

// Variables
// --------------------------
@sidebar-bg-color: 					#FFF; //@wc-light-gray; //#FFF; //@wc-light-blue;
@sidebar-child-bg-color: 			fade(#FFF, 50%); // lighten(@sidebar-bg-color, 5%);
@sidebar-grandchild-bg-color: 		fade(#FFF, 65%); // lighten(@sidebar-bg-color, 7%);
@sidebar-module-border: 			1px solid @secondary-site-color;

@sidebar-border-color: 				transparent;

@sidebar-gutter-indent: 			10px;
@sidebar-gutter-indent--lg: 		@common-gutter;
@sidebar-gutter-indent--xl: 		(@common-gutter * 2);

@sidebar-font-size: 				1em;
@sidebar-child-font-size: 			0.9em;
@sidebar-grandchild-font-size: 		0.8em;
@sidebar-link-color: 				@global-text-color;
@sidebar-link-bg-color--hover: 		@block-link-bg-color--hover;
@sidebar-link-color--hover: 		#000;

@sidebar-heading-padding-top: 		0.75em;
@sidebar-heading-padding-bottom: 	0.75em;
@sidebar-heading-bg-color: 			#FFF;
@sidebar-heading-font-size: 		1.1em;
@sidebar-heading-font-weight: 		normal;
@sidebar-heading-text-transform: 	uppercase;
@sidebar-heading-color: 			@secondary-site-color;

@sidebar-current-page-color: 		@primary-site-color;
@sidebar-current-page-before: 		"> ";
// --------------------------

// Main styles
// --------------------------
.global-sidebar-nav {
	border-bottom: @sidebar-module-border;
	margin-left: -(@common-gutter);

	background: @sidebar-bg-color;
	.font-smoothing();

	@media (min-width: (@screen-xl-min + 1)) {
		margin-left: 0;
	}
}

.global-sidebar-module {
	border-top: @sidebar-module-border;
}

.global-sidebar-heading {
	padding-left: @sidebar-gutter-indent;
	padding-top:    @sidebar-heading-padding-top;
	padding-bottom: @sidebar-heading-padding-bottom;
	line-height: 1.1;
	margin: 0;

	background: @sidebar-heading-bg-color;
	font-size: @sidebar-heading-font-size;
	font-weight: @sidebar-heading-font-weight;
	text-transform: @sidebar-heading-text-transform;
	color: @sidebar-heading-color;

	> a {
		text-decoration: none !important;
		color: @sidebar-heading-color;
	}
}

.global-sidebar-nav-list {
	padding: 0;
	margin: 0 0 1em;

	list-style: none;
}

.global-sidebar-nav-li {
	// border-bottom: 1px solid darken(@sidebar-bg-color, 3%);

	&.is-current-page {

		> .global-sidebar-nav-link {
			background-color: #FFF; //darken(@sidebar-bg-color, 10%);
			font-weight: bold;
			color: @sidebar-current-page-color; //@global-text-color;

			cursor: default;
			cursor: e-resize;

			&:before {
				content: @sidebar-current-page-before;
			}
		}
	}
}

.global-sidebar-nav-link {
	display: block;
	padding-left: @sidebar-gutter-indent;
	padding-top:    0.35em;
	padding-bottom: 0.35em;
	padding-right: (@common-gutter / 2);

	line-height: 1.1;

	font-size: @sidebar-font-size;
	color: @sidebar-link-color;

	&:focus,
	&:hover {
		background-color: @sidebar-link-bg-color--hover;
		color: @sidebar-link-color--hover;
		text-decoration: none;
	}
}

// Child lists
.global-sidebar-nav-list-child {

	margin-bottom: 0;

	background-color: @sidebar-child-bg-color;

	.global-sidebar-nav-li {
		//
		&:last-child {
			border-bottom: 0;
		}
	}
	.global-sidebar-nav-link {
		padding-left: (@sidebar-gutter-indent * 1.6);

		font-size: @sidebar-child-font-size;
	}
}

// Grandchild lists
.global-sidebar-nav-list-child .global-sidebar-nav-list-child {
	background-color: @sidebar-grandchild-bg-color;

	.global-sidebar-nav-link {
		padding-left: (@sidebar-gutter-indent * 2.2);

		font-size: @sidebar-grandchild-font-size;
	}
}

// Layout changes w/ width
// --------------------------
@media (min-width: @screen-lg-min) {
	.global-sidebar-nav {
		font-size: 1.1em;
	}

	.global-sidebar-heading {
		padding-left: @sidebar-gutter-indent--lg;
	}
	.global-sidebar-nav-link {
		padding-left: @sidebar-gutter-indent--lg;
	}
	// Child lists
	.global-sidebar-nav-list-child {

		.global-sidebar-nav-link {
			padding-left: (@sidebar-gutter-indent--lg * 1.6);
		}
	}
	// Grandchild lists
	.global-sidebar-nav-list-child .global-sidebar-nav-list-child {

		.global-sidebar-nav-link {
			padding-left: (@sidebar-gutter-indent--lg * 2.2);
		}
	}
}

@media (min-width: @screen-xl-min) {
	.global-sidebar-nav {
		font-size: 1.2em;
	}

	.global-sidebar-heading {
		padding-left: @sidebar-gutter-indent--xl;
	}
	.global-sidebar-nav-link {
		padding-left: @sidebar-gutter-indent--xl;
	}
	// Child lists
	.global-sidebar-nav-list-child {

		.global-sidebar-nav-link {
			padding-left: (@sidebar-gutter-indent--xl * 1.6);
		}
	}
	// Grandchild lists
	.global-sidebar-nav-list-child .global-sidebar-nav-list-child {

		.global-sidebar-nav-link {
			padding-left: (@sidebar-gutter-indent--xl * 2.2);
		}
	}
}
