//
// Grid - Extensions to BS3 grid + customizations
// --------------------------------------------------


// Containers!
// ---------------------------------------
.container {
	// .clear_float ();

	position: relative;

	min-width: @site-min-width;
	width: auto;

	@media (min-width: @site-max-width) {
		max-width: @site-max-width;
	}
}

// Container with no max-width
// --------------------------
.container-fluid {
	// .clear_float ();

	position: relative;

	min-width: @site-min-width;
}

// Container whose max-width fits to the designated XL size
// --------------------------
.container-xl {
	width: auto;
	max-width: @screen-xl-min;
}

// Container that does not have gutters
// --------------------------
.container-gutterless {
	padding-left:  0 !important;
	padding-right: 0 !important;
}



// XS size specific styling/layout adjustments
// ---------------------------------------
@media (max-width: @screen-xs-max) {

	// Reduce gutter size between columns
	[class*="col-"] {
		padding-left:  @mobile-column-gutter;
		padding-right: @mobile-column-gutter;
	}

	// Reduce container gutter size
	.container {
		padding-left:  @mobile-container-gutter;
		padding-right: @mobile-container-gutter;
	}

	//
	.row {
		margin-left:  -(@mobile-column-gutter); // 0;
		margin-right: -(@mobile-column-gutter); // 0;
	}

	// // Break the column out of its container
	// .col-xs-break-out,
	// .xs-break-out {
	// 	// margin-left:  -(@mobile-container-gutter + @mobile-column-gutter);
	// 	// margin-right: -(@mobile-container-gutter + @mobile-column-gutter);
	// 	margin-left:  -(@mobile-container-gutter);
	// 	margin-right: -(@mobile-container-gutter);
	// }
	// // Remove padding
	// .xs-no-padding {
	// 	padding: 0 !important;
	// }
	// .xs-collapse-margins {
	// 	margin-top: 0 !important;
	// 	margin-bottom: 0 !important;
	// }
}


// Extra Large grid
// An extension of the BootStrap 3 grid. Adding a greater range for more control.
//
// Columns, offsets, pushes, and pulls for the extra large desktop device range.
//
// Note that `.col-lg-12` doesn't get floated on purpose--there's no need since
// it's full-width.
// ---------------------------------------

@media (min-width: @screen-xl-min) {
	.col-xl-1,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-10,
	.col-xl-11 {
		float: left;
	}
	.col-xl-1  { width: percentage((1 / @grid-columns)); }
	.col-xl-2  { width: percentage((2 / @grid-columns)); }
	.col-xl-3  { width: percentage((3 / @grid-columns)); }
	.col-xl-4  { width: percentage((4 / @grid-columns)); }
	.col-xl-5  { width: percentage((5 / @grid-columns)); }
	.col-xl-6  { width: percentage((6 / @grid-columns)); }
	.col-xl-7  { width: percentage((7 / @grid-columns)); }
	.col-xl-8  { width: percentage((8 / @grid-columns)); }
	.col-xl-9  { width: percentage((9 / @grid-columns)); }
	.col-xl-10 { width: percentage((10/ @grid-columns)); }
	.col-xl-11 { width: percentage((11/ @grid-columns)); }
	.col-xl-12 { width: 100%; }

	// Push and pull columns for source order changes
	.col-xl-push-0  { left: auto; }
	.col-xl-push-1  { left: percentage((1 / @grid-columns)); }
	.col-xl-push-2  { left: percentage((2 / @grid-columns)); }
	.col-xl-push-3  { left: percentage((3 / @grid-columns)); }
	.col-xl-push-4  { left: percentage((4 / @grid-columns)); }
	.col-xl-push-5  { left: percentage((5 / @grid-columns)); }
	.col-xl-push-6  { left: percentage((6 / @grid-columns)); }
	.col-xl-push-7  { left: percentage((7 / @grid-columns)); }
	.col-xl-push-8  { left: percentage((8 / @grid-columns)); }
	.col-xl-push-9  { left: percentage((9 / @grid-columns)); }
	.col-xl-push-10 { left: percentage((10/ @grid-columns)); }
	.col-xl-push-11 { left: percentage((11/ @grid-columns)); }

	.col-xl-pull-0  { right: auto; }
	.col-xl-pull-1  { right: percentage((1 / @grid-columns)); }
	.col-xl-pull-2  { right: percentage((2 / @grid-columns)); }
	.col-xl-pull-3  { right: percentage((3 / @grid-columns)); }
	.col-xl-pull-4  { right: percentage((4 / @grid-columns)); }
	.col-xl-pull-5  { right: percentage((5 / @grid-columns)); }
	.col-xl-pull-6  { right: percentage((6 / @grid-columns)); }
	.col-xl-pull-7  { right: percentage((7 / @grid-columns)); }
	.col-xl-pull-8  { right: percentage((8 / @grid-columns)); }
	.col-xl-pull-9  { right: percentage((9 / @grid-columns)); }
	.col-xl-pull-10 { right: percentage((10/ @grid-columns)); }
	.col-xl-pull-11 { right: percentage((11/ @grid-columns)); }

	// Offsets
	.col-xl-offset-0  { margin-left: 0; }
	.col-xl-offset-1  { margin-left: percentage((1 / @grid-columns)); }
	.col-xl-offset-2  { margin-left: percentage((2 / @grid-columns)); }
	.col-xl-offset-3  { margin-left: percentage((3 / @grid-columns)); }
	.col-xl-offset-4  { margin-left: percentage((4 / @grid-columns)); }
	.col-xl-offset-5  { margin-left: percentage((5 / @grid-columns)); }
	.col-xl-offset-6  { margin-left: percentage((6 / @grid-columns)); }
	.col-xl-offset-7  { margin-left: percentage((7 / @grid-columns)); }
	.col-xl-offset-8  { margin-left: percentage((8 / @grid-columns)); }
	.col-xl-offset-9  { margin-left: percentage((9 / @grid-columns)); }
	.col-xl-offset-10 { margin-left: percentage((10/ @grid-columns)); }
	.col-xl-offset-11 { margin-left: percentage((11/ @grid-columns)); }
}
