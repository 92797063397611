//
// Layout - Desktop
// --------------------------------------------------

@media (min-width: @desktop-styles-min) {
	// Header
	// ---------------------------------------
	// .global-header {
	// 	//
	// }

	.global-header-container {
		.clear_float();
	}

	.global-logo {
		top: 9px;

		width: 16em;
	}

	// Main Nav
	// ---------------------------------------
	.global-main-nav {
		margin-right: 0;

		// Fix not being able to click on announcement by hiding the generated clearfix element
		&:after {
			display: none;
		}

		.global-nav-li {
			//
			&:focus,
			&:hover,
			&.is-open {
				//

				> .global-nav-link {
					background: fade(#000, 10%);
					// background: linear-gradient(to bottom, darken(@header-bg-color, 10%) 0%, darken(@header-bg-color, 20%) 100%);
					background-color: @megamenu-bg-color;
					color: @link-color; //#FFF;
				}
			}
		}
		.global-nav-link {
			height: 3em;
			height: @navbar-height;
			line-height: 3em;
			line-height: @navbar-height;

			font-size: .80em; // 0.85em; before the "Blog" link was additionally added // .925em; before the Mully link was added
			letter-spacing: 0.0125em;
			color: #FFF;

			// &:focus,
			// &:hover {
			// 	background: fade(#000, 10%);
			// }
		}

	}

	.global-main-nav-list > .global-nav-li > .global-nav-link {
		padding: 0 0.65em;
	}

	// .global-main-menu {
	// 	//
	// }



	.mobile-submenu-opener {
		display: none;
	}


	// Child Lists (normal dropdown navs, which megamenus extend)
	// ---------------------------------------
	.global-nav-child-menu {
		// Hiding
		overflow: hidden;
		max-height: 0;
		height: auto;

		position: absolute;
		top: 100%;
		right: 0;
		left: auto;
		z-index: 10;

		background-color: @child-nav-bg-color;
		.box-shadow(@child-nav-box-shadow);

		.transition(all 0s ease-in 0.25s);
		// adding this fixes a Safari bug where somehow the .megamenu-inner is hoverable despite this element having no height and being overflow hidden... added by KB, June 14, 2016. Retested navigation on Safari / Chrome / FF / IE 10, 11, Edge
		// found on a mostly unrelated thread: http://stackoverflow.com/questions/28488059/safari-rendering-bug-with-css-clip
		clip: rect(0, auto, auto, 0);

		// Children _________
		.global-nav-li {
			display: block;
			height: auto;
			line-height: 1.15;
			margin: 0;

		}
		.global-nav-link {
			display: block;
			height: auto;
			min-width: 200px;
			padding: 0.75em 1em;

			text-align: right;
			line-height: 1.15;
			color: @link-color;

			&:focus,
			&:hover {
				background-color: @block-link-bg-color--hover !important;
				// color: #FFF;
			}
		}

		// States _________

		// Unhide / reveal method
		.global-nav-li:focus > &,
		.global-nav-li:hover > &,
		.global-nav-li.is-open > & {
			z-index: 11;
			max-height: @megamenu-max-height;
			max-height: 80vh;
			max-height: -webkit-calc(~"100vh - @{header-height}");
			max-height:    -moz-calc(~"100vh - @{header-height}");
			max-height:         calc(~"100vh - @{header-height}");

			overflow-y: auto;

			// // Scrollbar fix attempts _________
			// Hide the scrollbars if possible
			overflow-y: -moz-scrollbars-none;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			-ms-overflow-style: none;
			&::-webkit-scrollbar {
				display: none;
			}
			// // /end Scrollbar fix attempts _________

			.transition(all 0.4s ease-in 0.05s);
		}
	}

	// IF the top level menu hover is marked as being for a child menu (as opposed to a megamenu), then allow for that list to be overflow hidden
	.global-main-nav-list > .global-nav-li--has-child-menu:focus > .global-nav-list,
	.global-main-nav-list > .global-nav-li--has-child-menu:hover > .global-nav-list,
	.global-main-nav-list > .global-nav-li--has-child-menu.is-open > .global-nav-list {
		overflow: hidden;
	}



	.global-nav-list .global-nav-list {
		.global-nav-list {
			position: static;
			top: auto;

			overflow: visible;
			height: auto;
			max-height: none;
			border: 0;

			background: none;
			box-shadow: none;

			// Children _________
			.global-nav-link {
				padding-left: 0;
			}
		}
	}


	// Megamenu
	// ---------------------------------------

	// Elements
	// --------------------------
	.global-nav-li--has-megamenu {
		position: static;
	}

	.megamenu {
		// Normal styles
		left: 0;

		.scrolling-shadow-indication ();
		background-color: @megamenu-bg-color;

		// Children _________
		.global-nav-megamenu-list {
			-webkit-columns: @megamenu-list-column-width;
			   -moz-columns: @megamenu-list-column-width;
			        columns: @megamenu-list-column-width;
			-webkit-column-gap: @megamenu-list-column-gap;
			   -moz-column-gap: @megamenu-list-column-gap;
			        column-gap: @megamenu-list-column-gap;

			> .global-nav-megamenu-li {
				-webkit-column-break-inside: avoid;
				          page-break-inside: avoid;
				               break-inside: avoid;
				padding-bottom: 5px;
			}
		}

		.global-nav-list {
			display: block;
			height: auto;
			margin-bottom: 1em;
		}
		.global-nav-li {

			.global-nav-link {
				display: block;
				// height: auto;
				min-width: 0;
				padding: 0.15em 0;

				text-align: left;
				font-size: 1.10em;
				// line-height: 1.15;
				// color: @link-color;
			}
		}
		.global-nav-megamenu-child-list {
			margin-bottom: 10px;

			// Children _________
			.global-nav-link {
				font-size: .9090909em;
				font-weight: normal;
				color: @global-text-color;

				&:focus,
				&:hover {
					color: #000;
				}
			}
		}
	}
	.megamenu-inner {
		padding: @common-gutter (@common-gutter * 2) 1px; // Side padding expands at wider widths -- media queries below in this file
	}

	// .global-nav-megamenu-list {}

	.global-nav-megamenu-micro-list {
		.global-nav-link {
			font-size: 0.9em !important;
			font-weight: normal;
			color: @global-text-color;

			&:focus,
			&:hover {
				color: #000 !important;
			}
		}
	}

	.megamenu-list--two-cols-wide {
		width: (@megamenu-list-column-width +
				@megamenu-list-column-gap +
				@megamenu-list-column-width);
	}
	.megamenu-list--three-cols-wide {
		width: (@megamenu-list-column-width +
				@megamenu-list-column-gap +
				@megamenu-list-column-width +
				@megamenu-list-column-gap +
				@megamenu-list-column-width);
	}
	.megamenu-list--four-cols-wide {
		width: (@megamenu-list-column-width +
				@megamenu-list-column-gap +
				@megamenu-list-column-width +
				@megamenu-list-column-gap +
				@megamenu-list-column-width +
				@megamenu-list-column-gap +
				@megamenu-list-column-width);
	}
	.megamenu-list--align-right {
		margin-left: auto;
	}

	// Megamenu-only content blocks
	// --------------------------
	.megamenu-content {
		// Show on desktop
		display: block;
	}


	// Utility Nav
	// ---------------------------------------
	.global-utility-nav {
		top: 0; //-(@utility-nav-height);

		background: desaturate(darken(@utility-nav-bg-color, 5%), 5%);
	}
	// .global-utility-nav-list {
	// 	// border-right: 1px solid fade(#000, 25%);
	// }
	.global-utility-nav-li {
		vertical-align: top;
		// border-left: 1px solid fade(#000, 25%);
		margin-right: -.25em;

		&:last-child {
			margin-right: 0;
		}
	}
	.global-utility-nav-link {
		padding: 0 0.75em;
		height: @utility-nav-height;
		line-height: @utility-nav-height;

		font-weight: normal;
		//
		&:focus,
		&:hover {
			background-color: fade(#FFF, 15%);
		}
	}

	.utility-cart-link,
	.utility-order-link,
	.utility-account-link {
		svg {
			margin-top: 3px;
			margin-right: 0.25em;
			margin-left: 0;
		}
	}

		.utility-order-link {
			svg {
				margin-top: -1px;
			}
		}


	// Global Main
	// ---------------------------------------
	.global-main {
		margin-top: @header-height;

		// Generated content shadow while megamenu is open
		.child-menu-is-open &::after {
			// content: " ";
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 3; // 3 to bring it in front of the footer
			background: fade(#000, 50%);
		}
	}
}


// Special desktop width + _ideal case_ height for megamenus being allowed to be overflow: hidden
@media (min-width: @desktop-styles-min) and (min-height: 750px) {
	.global-main-nav-list > .global-nav-li:focus > .global-nav-child-menu,
	.global-main-nav-list > .global-nav-li:hover > .global-nav-child-menu,
	.global-main-nav-list > .global-nav-li.is-open > .global-nav-child-menu {
		overflow: hidden;
	}
}


// LG styles
@media (min-width: @screen-lg-min) {
	.global-logo {
		width: 300px;
	}

	.megamenu-inner {
		padding-left:  6%;
		padding-right: 3%;
	}
}

// LG / XL midpoint
@media (min-width: ((@screen-xl-min - @screen-lg-min) / 2 + @screen-lg-min)) {
	.global-main-nav-list > .global-nav-li > .global-nav-link {
		padding: 0 0.85em;

		font-size: .925em; // font size increase added because @ desktop min this has been set lower thanks to the newer "Mully" top level nav link
	}
}

// XL styles
@media (min-width: @screen-xl-min) {
	.megamenu-inner {
		padding-left: 8%;
		padding-right: 4%;
	}
}
