//
// Utility classes
// --------------------------------------------------


// Display
// ---------------------------------------
.inline {
	display: inline !important;
}
.inline-block {
	display: inline-block !important;
}
.block {
	display: block !important;
}



// Layout & Box Model
// ---------------------------------------
.width-full {
	width: 100% !important;
}
.width-self {
	display: inline-block !important;
	width: auto !important;
}

.no-margin {
	margin: 0 !important;
}
.no-margin-top {
	margin-top: 0 !important;
}
.no-margin-bottom {
	margin-bottom: 0 !important;
}
.margin-center {
	margin-left:  auto !important;
	margin-right: auto !important;
}



// Floats & Clearing
// ---------------------------------------
.pull-right {
	float: right !important;
}
.pull-left {
	float: left !important;
}
.no-float {
	float: none !important;
}

// Clearfixin'
// --------------------------
// * http://nicolasgallagher.com/micro-clearfix-hack/
// *
// * .clear_float is the LESS mixin;
// * .clearfix is a regular CSS class
// --------------------------
.clearfix {
	.clear_float ();
}



// Font + Text helpers
// ---------------------------------------

// Font family
.font-family-serif 		{ font-family: @font-family-serif; }
.font-family-sans 		{ font-family: @font-family-sans; }
.font-family-clean 		{ font-family: @font-family-clean; }

// Text size
.text-larger 			{ font-size: 1.2em; }
.text-smaller 			{ font-size: 0.9em; }
.text-tiny 				{ font-size: 0.8em; }
.text-normal 			{ font-size: 1.0em; }

// Text formatting
.text-bold				{ font-weight: bold !important; }
.text-italic			{ font-style: italic !important; }
.text-normal			{ font-weight: normal !important; font-style: normal !important; }

.text-lowercase 		{ text-transform: lowercase !important; }
.text-uppercase 		{ text-transform: uppercase !important; }
.text-capitalize 		{ text-transform: capitalize !important; }

.text-underline 		{ text-decoration: underline !important; }
.text-strikethrough		{ text-decoration: line-through !important; }
.text-undecorated 		{ text-decoration: none !important; }

// Text alignment
.text-center 			{ text-align: center !important; }
.text-left   			{ text-align: left !important; }
.text-right  			{ text-align: right !important; }

// Text colors
.text-color-alert 		{ color: @alert-color !important; }
.text-color-warning 	{ color: @warning-color !important; }
.text-color-error,
.text-color-danger 		{ color: @error-color !important; }
.text-color-success 	{ color: @success-color !important; }
.text-color-info 		{ color: @info-color !important; }

.text-color-default 	{ color: @global-text-color !important; }



// Images
// ---------------------------------------
.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}



// JS toggles
// ---------------------------------------
.is-mobile-scroll-locked {
	overflow: hidden;
	position: fixed;
	height: 100%;
	width: 100%;

	// In landscape views this seems to zoom way in to the page. So this fixes it
	@media all and (orientation: landscape) {
		position: static;
		height: auto;
		width: auto;
	}
	@media (min-width: @desktop-styles-min) {
		overflow: visible;
		position: relative;
		height: auto;
		width: auto;
	}
}



// Hiding / Showing Content
// ---------------------------------------

// Screenreader only
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
	border: 0;
}
.invisible {
	visibility: hidden;
}
.hide {
	display: none;
}
// Hide from screenreaders and browsers:  HTML5 Boilerplate
.hidden {
	display: none !important;
	visibility: hidden !important;
}
.show {
	display: block;
	visibility: visible;
}
