//
// Parsely.js form validation styles
// --------------------------------------------------

// .parsley-validated {

// }
.parsley-success {
	border: 1px solid @success-color !important;

	background-color: lighten(@success-color, 45%) !important;
	color: darken(@success-color, 10%) !important;
}
.parsley-error {
	border: 1px solid @error-color !important;

	background-color: lighten(@error-color, 45%) !important;
	color: darken(@error-color, 10%) !important;
}
.parsley-errors-list {
	padding: 0;
	margin: 2px 0 3px;

	list-style-type: none;

	font-size: 0.9em;
	line-height: 0.9em;
	opacity: 0;

	-webkit-transition: all .3s ease-in;
	transition: all .3s ease-in;
}

.parsley-errors-list.filled {
	opacity: 1;
}
