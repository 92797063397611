//
// Layout - Global
// --------------------------------------------------


// Wrappers
// ---------------------------------------
html {
	// min-height: 100%;

	font-family: @font-family-base;
	// font-size: @font-size-base-mobile;
	// font-weight: normal;
	// color: @global-text-color;

	// background-color: @main-content-bg-color;

	// overflow: auto;

	// // Scale up global font size at larger widths
	// @media (min-width: @screen-md-min) {
	// 	font-size: @font-size-base-tablet;
	// }
	// @media (min-width: @screen-lg-min) {
	// 	font-size: @font-size-base;
	// }
}

// body {
// 	position: relative;

// 	min-height: 100vh; // Force the body to be at least as high as the viewport

// 	background-color: @extended-footer-bg-color;

// 	line-height: @line-height-base;
// }

// .global-wrapper {
// 	.clear_float ();
// 	position: relative;
// 	height: 100%;
// }
