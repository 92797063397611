//
// Inside page
// --------------------------------------------------

.page-inside-page {
	//
	.main-content {
		max-width: 65em;
		margin-left: auto;
		margin-right: auto;
	}
}
