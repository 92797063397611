//
// Layout - Desktop
// --------------------------------------------------

@media (min-width: @desktop-styles-min) {

	// Header
	// ---------------------------------------
	.global-header {
		padding-top: @utility-nav-height;
		height:      @header-height;
		line-height: @header-height;
		top: -(@header-height);
	}


	// Navigation Wrapper
	// ---------------------------------------
	.global-outer-navigation-wrapper {
		// undo the hiding for mobile
		display: block;
		margin-top: 0;
		overflow-y: visible;

		position: static;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		z-index: 1;

		background-color: transparent;

		.animation(none 0s ease 0s) !important;
	}


	// Base Nav styles
	// ---------------------------------------
	.global-nav {
		height: @navbar-height;

		.clear_float ();

		float: right;

		margin-right: 4em;
		  @the-right-margin: (@search-reveal-button-size - .75rem);
		// .calc-margin-right(~"@{the-right-margin} + 1.6em");
	}

	.global-nav-list {
		display: inline-block;
		height: @navbar-height;
	}
	.global-nav-list {
		.animation (none);
	}

	.global-nav-li {
		//
		height: @navbar-height;

		// Top level menus only
		.global-nav-list > & {
			display: inline-block;
			margin-right: -0.32em;

			> .global-nav-link {
				display: inline-block;
				vertical-align: top;
			}
		}
	}

	.global-nav-link {
		height: 3em;
		height: @navbar-height;
		line-height: 3em;
		line-height: @navbar-height;
		padding: 0 0.85em;

		font-size: 0.9em;
		font-weight: bold;

		&:before {
			display: none;
			content: "";
		}
	}

	// the <li> that has a .global-nav-list-child
	.global-nav-li-has-children {
		position: relative;
	}

	.global-nav-list-child {
		// undo mobile hiding
		display: block;

		position: absolute;
		// left: 0;
		right: 0;
		top: 90%;
		z-index: 1000;

		min-width: 320px;

		// Hiding method
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		  // @the-transition: top .3s ease .15s, opacity .3s ease .15s, max-height 0s linear 0;
		  @the-transition: top .3s ease .15s, opacity .3s ease .15s, max-height .3s linear .15s;
		.transition (@the-transition);

		// height: @navbar-height;
		// padding-right: 4em;
		//   @the-right-padding: (@search-reveal-button-size - .45rem);
		// .calc-padding-right(~"@{the-right-padding} + 1.6em");

		// float: left;
		// min-width: 2em;

		background: darken(@navigation-bg-color, 4%);
		background: linear-gradient(to bottom, darken(@navigation-bg-color, 7%) 0%, darken(@navigation-bg-color, 4%) 15%);
		text-align: right;
		font-size: .9em;
		color: @link-color;
		  @the-shadow: 0 .25em .25em 0 fade(#000, 25%);
		.box-shadow (@the-shadow);

		// States
		.global-nav-li-has-children.is-open > &,
		.global-nav-li-has-children:focus > &,
		.global-nav-li-has-children:hover > & {
			background-color: darken(@navigation-bg-color, 4%);

			// display: block;
			max-height: 2000px;
			top: 100%;
			opacity: 1;
			  @the-transition: top .3s ease .15s, opacity .3s ease .15s, max-height .3s linear .15s;
			.transition (@the-transition);
		}

		// Children
		.global-nav-li {
			display: block;
		}
		.global-nav-link {
			display: block;
			// padding: .5em 1em;

			clear: both;
			white-space: nowrap;

			padding-left: 1em;
		}

		// Grandchildren etc.
		.global-nav-list-child {
			top: 0;
			left: 100%;
		}
	}

	// Utility Nav
	// ---------------------------------------
	.global-utility-nav {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 11;

		height: @utility-nav-height;
		line-height: @utility-nav-height;
		margin-right: 0;

		background-color: @utility-nav-bg-color;
		text-align: right;
	}

	.global-utility-nav-list {
		height: @utility-nav-height;
		line-height: @utility-nav-height;
	}

	.global-utility-nav-li {
		display: inline-block;
		margin-right: -.75em; // shift them right so the last item optically aligns with top nav on desktop
		// margin-left: 1.5em;

		// Generated marker in between nav elements
		// &:before {
		// 	content: "\2022";

		// }
		// &:first-child {
		// 	&:before {
		// 		display: none;
		// 	}
		// }
	}

	.global-utility-nav-link {
		height: @utility-nav-height;
		line-height: @utility-nav-height;

		// font-family: @font-family-helvetica;
		// font-size: .8125em;
		// text-transform: uppercase;
		// font-weight: normal;
		color: @utility-nav-link-color;
	}


	// Main Nav
	// ---------------------------------------
	// .global-main-nav {
	// 	//
	// }


	// Logo
	// ---------------------------------------
	.global-logo {
		left: @logo-side-margin;
	}
	.global-logo-image {
		height: @global-logo-height;
	}


	// open/close links
	// ---------------------------------------
	.animated-responsive-menu-link,
	.responsive-menu-closer {
		display: none !important;
	}

	.global-search-reveal-button {
		top: 0;

		right: 1.6em;
		right: 1.6rem;

		width: 2.6em;
		width: (@search-reveal-button-size - .5rem);

		// &:before {
			font-size: 1em;
		// }
	}


	// misc
	// ---------------------------------------

	.full-page-menu {
		padding-top: -webkit-calc(~"@{header-height} + 2em");
		padding-top: -moz-calc(~"@{header-height} + 2em");
		padding-top: calc(~"@{header-height} + 2em");
	}

	.global-main {
		margin-top: @header-height;
	}
}
