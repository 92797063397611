//
// Forms
// --------------------------------------------------


// Form Layout & Helper Classes
// ---------------------------------------
.form-row {
	margin-left:  -(@grid-gutter-width / 2);
	margin-right: -(@grid-gutter-width / 2);

	.form-group,
	.form-column {
		padding-left:  (@grid-gutter-width / 2);
		padding-right: (@grid-gutter-width / 2);
	}
}

.form-column {
	margin-bottom: 1.2em;
}

// .form-field-block {
// 	//
// }

// Thinner form
// --------------------------
.form-thinner {
	//
	.form-row {
		margin-left:  -(@grid-gutter-width / 4);
		margin-right: -(@grid-gutter-width / 4);

		.form-group,
		.form-column {
			padding-left:  (@grid-gutter-width / 4);
			padding-right: (@grid-gutter-width / 4);
		}
	}
}


// Form Elements
// ---------------------------------------
fieldset {
	padding: 0;
	border: 0;
	margin: 0;
}
legend {
	padding-bottom: 4px;
}
label {
	display: block;

	// font-size: 12px;
	// text-transform: uppercase;
}

// Input styling
// --------------------------
.input-field {
	.box-sizing (border-box);

	display: inline-block;
	padding: @input-padding;
	width: 100%;
	border: @input-border-width solid @input-border-color;
	border-radius: @input-border-radius;

	background-color: @input-bg;
	font-family: @input-font-family;
	font-size: @input-font-size; // == 16px --> this prevents mobile browsers from zooming in to the input field when it is selected
	line-height: @input-line-height;
	color: @input-color;

	.box-shadow (@input-box-shadow);

	.transition (@input-transition);

	// States _________
	&:hover {
		background-color: @input-bg--highlighted;
		border-color: lighten(@input-border-color, 05%);

		.box-shadow (@input-box-shadow--hovered);
	}
	&:focus {
		background-color: @input-bg--highlighted;
		border-color: @focus-highlight-color;

		.box-shadow (@input-box-shadow--focused);
	}
	&[disabled] {
		background-color: @input-bg;
		border-color: @input-border-color;

		.box-shadow (@input-box-shadow);
	}

	// Style overrides for textarea _________
	textarea& {
		padding: @input-textarea-padding;
		margin-right: 0; // Not sure what this is for... -- March 31, 2016 KB

		line-height: @input-textarea-line-height;
	}

	// If browser supports box-shadow (detected via Modernizr),
	// remove default outline focus styling, since we duplicate & enhance the effect with
	// box-shadow's
	.mod-boxshadow & {
		outline: 0;
	}

}

.input-autowidth {
	width: auto;
}


// Input placeholder coloring
// ---------------------------------------
::-webkit-input-placeholder 	{ color: @input-color-placeholder !important; }
:-moz-placeholder           	{ color: @input-color-placeholder !important; } // old syntax
::-moz-placeholder          	{ color: @input-color-placeholder !important; }
:-ms-input-placeholder      	{ color: @input-color-placeholder !important; }
:placeholder-shown          	{ color: @input-color-placeholder !important; }



// Radio & Checkbox Assistance
// ---------------------------------------
// *	useful for CC radios, etc.
// ---------------------------------------

// DEPRECATED...MIGHT BE USEFUL IN A REFACTOR, SO KEEPING AROUND A BIT. October 13, 2015 -- K
// // Label Wraps
// // ------------------------
// .label-wrap {
// 	margin-right: (@common-gutter / 2);
// 	margin-bottom: (@common-gutter / 2);
// 	// &:last-of-type { margin-bottom: 0; }
// }
// .label-wrap-input {
// 	display: inline-block;
// 	vertical-align: middle;
// }
// .label-wrap-img {
// 	display: inline-block;
// 	vertical-align: middle;
// }
// // >>> Usage:
// // <label><input><img></label>

// Horizontal Layout
// ------------------------
.horizontal-inputs,
.vertical-inputs {
	// make inputs & labels horizontal
	label {
		display: inline-block;
		vertical-align: middle;
		.transition (all .1s ease-out);

		// Further indicate hovered label is clickable
		&:focus,
		&:hover {
			background-color: fade(#fff, 50%);
			  @the-shadow: 0 0 0 3px #fff, 0 0 2px 4px fade(#000, 30%);
			.box-shadow (@the-shadow);
		}

		.label-contents {
			display: inline-block;
			vertical-align: middle;
		}
	}
	input[type="radio"],
	input[type="checkbox"] {
		display: inline-block;
		margin-left: 10px;
		margin-right: 1px;
		vertical-align: middle;

		&:first-child { margin-left: 1px; }
	}
	// .label-wrap {
	// 	//
	// }
}
.vertical-inputs {
	label {
		display: block;
	}
}


.wrapping-label {
	position: relative;
	margin-bottom: .5em;

	font-size: .9em;
	font-weight: normal;

	.transition (all .1s ease-out);

	// Indicate hovered label is clickable
	&:hover {
		background-color: fade(#000, 07%);
		outline: 1px solid lighten(#000, 80%);
		  @the-shadow: 0 0 3px 1px fade(#000, 20%);
		.box-shadow (@the-shadow);
	}

	.label-contents {
		.clear_float();

		display: block;
		padding: .1em .2em .1em 25px;
		// padding-left: 25px;
		// padding-right: .2em;

		outline: 2px solid fade(@focus-highlight-color, 0%);

		.transition (all .12s ease-out);
	}
	input {
		position: absolute;
		top: 50%;
		margin-top: -0.4em; // an attempt to vertically center these bad boys
		left: 4px;
	}
	input:focus ~ .label-contents {
		@the-shadow:
			0 0 0 2px #fff,
			0 0 0 3px @focus-highlight-color;
		.box-shadow(@the-shadow);
	}
	input:checked ~ .label-contents {
		// background-color: fade(@focus-highlight-color, 35%);
		background-color: fade(@focus-highlight-color, 15%);
		outline: 2px solid fade(@focus-highlight-color, 50%);
		text-shadow: 0 0.0625em 0.0625em #fff;
	}
	input:checked:focus ~ .label-contents {
		background-color: fade(@focus-highlight-color, 10%);
		outline: 2px solid fade(@focus-highlight-color, 50%);

		@the-shadow:
			0 0 0 2px #fff,
			0 0 0 3px #fff;
		.box-shadow(@the-shadow);
	}

	// DEPRECATED...WHAT DOES THIS DO? // MIGHT BE USEFUL IN A REFACTOR, SO KEEPING AROUND A BIT. October 13, 2015 -- K
	// // Conditional, Parent
	// .checkbox-bs-wrapper > & {
	// 	display: inline-block;
	// }
}
// Subtle variant
.wrapping-label-subtle {
	&:hover {
		background-color: fade(#fff, 0%) !important;
	}

	.label-contents {
		outline: none !important;
		background-color: fade(#fff, 0%) !important;
		.box-shadow (none) !important;
		border: 1px dotted transparent;

	}
	input:checked ~ .label-contents {
		background-color: fade(#fff, 10%) !important;
	}
	input:focus ~ .label-contents {
		border: 1px dotted #fff;
	}
}



// Input note. Usually helper text beneath the input field
// ---------------------------------------
.input-note {
	margin: .5em 0 0;
	font-size: .8em;
	font-style: italic;
	color: #777;
}



// Required symbol. Usually an * character.
// ---------------------------------------
.required-symbol {
	position: relative;
	top: 0.25em;
	vertical-align: baseline;

	color: @error-color;
	font-size: 1.5em;
	line-height: 0;
}
