//
// Buttons
// --------------------------------------------------


// Core Button class & extensions
// ---------------------------------------

// Variables
// --------------------------
@button-height: 				2em;
@button-line-height: 			(@button-height * .95);
@button-lesser-height: 			2.0em;
@button-tiny-height: 			1.75em;

@button-border-width: 			1px;
@button-border-color: 			@button-color;
@button-border-radius: 			0.33em;

@button-padding: 				0 1.0em;

@button-font-family: 			@font-family-base;
@button-font-size:				@font-size-base;

@button-color:					@link-color;
@button-highlight:				lighten(@button-color, 10%);
@button-shade:					darken(@button-color, 20%);

@button-font-weight: 			normal;
@button-text-transform: 		none;
@button-text-color: 			#FFFFFF;
@button-text-shadow: 			0 0.0825em 0.0825em fade(#000, 50%);

@button-box-shadow:				inset 0 -0.125em 0.375em 0 rgba(0,0,0,.25),
								inset 0 0.125em 0.375em rgba(255,255,255,.25),
								0 0.0625em 0.1875em 0 rgba(0,0,0,.25);

// Interaction
// --------------------------
@button-transition: 			all .12s ease-out;
@button-tap-highlight-color: 	fade(#000, 0%);

// States
// --------------------------
@button-highlight-text-color: 	#FFFFFF;
@button-shade-text-color: 		#CCCCCC;
@button-shade-text-shadow: 		0 -0.0625em 0.0625em fade(#000, 50%);
@button-active-transform: 		translateY(1px);
@button-active-box-shadow: 		inset 0 -0.125em 0.375em 0 rgba(0,0,0,.01),
								inset 0 0.1875em 0.125em 0 rgba(0,0,0,.2),
								0 0 0 0 rgba(0,0,0,.15);

// Extensions
// --------------------------

// Size extensions _________
@smaller-button-size:			@button-font-size / 1.20;	// ~ 15px @ base 18px
@smallest-button-size:			@button-font-size / 1.50;	// ~ 12px @ base 18px
@larger-button-size:			@button-font-size * 1.2222;	// ~ 22px @ base 18px
@largest-button-size:			@button-font-size * 1.4444;	// ~ 26px @ base 18px

// Layout extensions _________
@button-multiline-min-width: 	11.5em;
@button-multiline-line-height: 	1.25;
@button-multiline-padding: 		.75em 1.5em;

// Colored extensions _________
@alt-button-color: 				@alt-link-color;
@alt-button-highlight:			lighten(@alt-button-color, 10%);
@alt-button-shade:				darken(@alt-button-color, 15%);
//	|	|
@subtle-button-color: 			#F3F3F3;
@subtle-button-highlight:		lighten(@subtle-button-color, 10%);
@subtle-button-shade:			darken(@subtle-button-color, 30%);
@subtle-button-border-color: 	@subtle-button-shade;
@subtle-button-text-color: 		#333 !important;
@subtle-button-text-shadow: 	0 0.0825em 0.0825em rgba(255,255,255,.75);
@subtle-button-box-shadow: 		inset 0 -0.125em 0.375em 0 rgba(0,0,0,.10),
								inset 0 0.125em 0.375em rgba(255,255,255,.25),
								0 0.0625em 0.1875em 0 rgba(0,0,0,.10);
@subtle-button-border-highlight:	lighten(#000, 60%);
@subtle-button-active-text-shadow: 	0 -0.0625em 0.0625em rgba(255,255,255,.75);

// Functional extensions _________
@button-arrowed-character: 		"\25b6";
@button-arrowed-character-size:	.6em;
@button-arrowed-left-padding: 	.5em;
//	|	|
@button-arrowed-down-character:	"\25bc";
@button-arrowed-up-character: 	"\25b2";
@button-arrowed-chevron-character: 	"\00BB";
// --------------------------

// Elements
// --------------------------
.button {
	position: relative;
	display: inline-block;
	padding: @button-padding;
	height: @button-height;
	line-height: @button-line-height; // Helps with appearance of vertically centered text
	border: @button-border-width solid @button-border-color;
	border-radius: @button-border-radius;

	text-align: center;
	font-family: @button-font-family;
	font-size: @button-font-size;
	font-weight: @button-font-weight;
	text-transform: @button-text-transform;
	.font-smoothing ();
	color: @button-text-color;
	text-shadow: @button-text-shadow;
	text-decoration: none !important;
	background-color: @button-color;

	cursor: pointer;

	// Removes the highlight that appears when tapping
	-webkit-tap-highlight-color: @button-tap-highlight-color;

	// Removes double-tap-to-zoom functionality on touch IE browsers, effectively removing tap delay
	-ms-touch-action: none;

	white-space: nowrap;

	.box-shadow (@button-box-shadow);
	.transition (@button-transition);

	// states
	// --------------------------
	// &:visited {
	// 	color: #fff;
	// }
	&:focus,
	&:hover {
		background-color: @button-highlight;
		color: @button-highlight-text-color;
	}
	&:active {
		color: @button-shade-text-color;
		text-shadow: @button-shade-text-shadow;
		background-color: @button-shade;
		// remove outline on click
		outline: none;

		.transform (@button-active-transform);
		.box-shadow (@button-active-box-shadow);
	}

}

// size & layout extensions
// --------------------------

// Sizing _________
.button--smaller {
	font-size: @smaller-button-size;
}
.button--smallest {
	font-size: @smallest-button-size;
}
.button--larger {
	font-size: @larger-button-size;
}
.button--largest {
	font-size: @largest-button-size;
}

// Layout _________
.button--multiline {
	min-width: @button-multiline-min-width;
	max-width: 100%;
	height: auto;
	line-height: @button-multiline-line-height;
	padding: @button-multiline-padding;

	text-align: left;
	white-space: normal;
}
.button--fullwidth {
	width: 100%;
}

// color extensions
// --------------------------
.button--alt {
	border-color: @alt-button-color;
	background-color: @alt-button-color;

	&:focus,
	&:hover {
		background-color: @alt-button-highlight;
	}
	&:active {
		background-color: @alt-button-shade;
	}
}
.button--subtle {
	border-color: @subtle-button-border-color;
	background-color: @subtle-button-color;

	color: @subtle-button-text-color;
	text-shadow: @subtle-button-text-shadow;

	.box-shadow (@subtle-button-box-shadow);

	&:focus,
	&:hover {
		background-color: @subtle-button-highlight;

		border-color: @subtle-button-border-highlight;
	}
	&:active {
		background-color: @subtle-button-shade;
		text-shadow: @subtle-button-active-text-shadow;
	}
}
	.button--subtle--negative {
		border-color: @negative-color !important;
	}
	.button--subtle--positive {
		border-color: @positive-color !important;
	}

// Further button extensions
// --------------------------
.button--arrowed {
	vertical-align: top;
	.hardware-accelerate ();

	&:after {
		content: @button-arrowed-character;
		display: inline-block;
		padding-left: @button-arrowed-left-padding;

		// fixes iOS showing an emoji arrow by declaring a font that the device has, that includes the unicode glyph
		font-family: "Hiragino Kaku Gothic ProN", sans-serif;
		// end fix
		font-size: @button-arrowed-character-size;

		.transition(all .12s ease-out);
	}
	&:hover:after {
		.transform(translateX(0.2em));
	}

	// Variations _________
	&.button--arrowed--down {
		&:after {
			content: @button-arrowed-down-character;
			position: relative;
			top: -0.1em;
		}
		&:hover:after {
			.transform(translateX(0) translateY(0.2em));
		}
	}
	&.button--arrowed--up {
		&:after {
			content: @button-arrowed-up-character;
		}
		&:hover:after {
			.transform(translateX(0) translateY(-0.2em));
		}
	}
	&.button--arrowed--chevron {
		&:after {
			content: @button-arrowed-chevron-character;
			padding-left: .15em;
			font-size: 1.3em;
		}
	}
}

// -- END main .button object



// Corner Close button
// ---------------------------------------
// Note: Mobile first styles
// ---------------------------------------
.button-corner-close {

	// Variables
	// --------------------------
	@corner-close-dimension: 1.4em;
	// --------------------------

	.box-sizing ();

	position: absolute;
	top:   0;
	right: 0;
	z-index: 2; //Place above so the whole button is clickable
	display: inline-block;
	height: @corner-close-dimension;
	line-height: (@corner-close-dimension * .85);
	width: @corner-close-dimension;
	padding: 0;
	// padding-bottom: 1px;
	border: 0;
	border-radius: 0 0 0 0.2em;

	background-color: #777;
	background-color: fade(#000, 60%);
	text-align: center;
	text-decoration: none !important;
	color: #fff;
	font-family: @font-family-clean;
	font-size: 23px;
	font-weight: normal;

	.box-shadow ();

	&:focus,
	&:hover {
		background-color: #000;
		border-color: #fff;
	}
}
	// Override mobile-first styles so .button-corner-close resembles traditional lightbox close button
	@media (min-width: @screen-sm-min) {
		.button-corner-close {
			top:   -.65em;
			right: -.65em;
			border: 2px solid #fff;
			border-color: #fff;
			border-color: fade(#fff, 85%);
			border-radius: 2em;
		}
	}

// Extensions
// --------------------------
.button-corner-close--minimal {
	position: absolute;
	top: -.60em;
	right: 0;

	height: 1em;
	line-height: 1;
	width: 1em;
	border: 0;

	background: transparent;
	text-align: center;
	font-size: 3em;
	font-weight: normal;
	text-decoration: none !important;
}


// Button "Pulse Focus" Animation
// ---------------------------------------
// When button is focused, pulse the size to indicate focus
// ---------------------------------------
.button:focus,
.close:focus {
	// Syntax: <name> || <time> || <timing-function> || <delay> || <iteration-count> || <direction> || <fill-mode>
	.animation (pulse .3s ease 0s 1 normal both);
	// Cancel animation on hover
	&:hover { .animation (none); }
}
