//
// Utilities
// --------------------------------------------------

//
.discount--original-price {
	text-decoration: line-through;
}
.discounted-price {
	font-weight: bold;
	color: @error-color;
}



// Desktop only utilities
// ---------------------------------------
@media (min-width: @desktop-styles-min) {
	.desktop-hidden {
		display: none !important;
	}
}

.cs_info td {
	padding-left: 10px;
	padding-right: 10px;
}