//
// Plugin Customizations & Overrides
// --------------------------------------------------


// Bootstrap 3 component animations
// ---------------------------------------
.fade {
	opacity: 0;

	-webkit-transition: opacity 0.15s linear;
	transition: opacity 0.15s linear;
}
.fade.in {
	opacity: 1;
}
.collapse {
	display: none;
}
.collapse.in {
	display: block;
}
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;

	-webkit-transition: height 0.35s ease;
	transition: height 0.35s ease;
}



// Modals
// ---------------------------------------
.modal {
	// Indicate clicking outside .modal-dialog closes the modal
	cursor: auto;
	cursor: pointer;
	cursor: url("@{site-path}@{img-dir}/cursor-close-shadowed.png"), pointer;
	cursor: url("@{site-path}@{img-dir}/cursor-close-shadowed.png") 15 15, pointer;
}
.modal-dialog {
	// Reset the above rule for when mouse in .modal-dialog
	cursor: default;
}

.modal-backdrop {
	background-color: fade(#000, 75%);
}



// Carousels
// ---------------------------------------

// None yet!
