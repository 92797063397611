//
// Home Page Template
// --------------------------------------------------

@mobile-common-gutter: 10px;
@premier-gray-darker: #999;
@navigation-desktop-width: 300px;


// .page-home {
// 	//
	.home-page-content-container {
		padding-top: 0;
	}
// }

// Home Hero
// ------------------------------------
.home-hero {
	position: relative;
	overflow: hidden;

	margin-left:  -(@mobile-container-gutter);
	margin-right: -(@mobile-container-gutter);
	margin-bottom: @common-gutter;
}

.home-hero-inner {
	position: relative;
	overflow: hidden;

	// height: 0;
	// padding-top: 63%; // 0.6305555556 but manually floored down to avoid rounding errors in cropping
}

// .home-hero-heading {
// 	position: absolute;
// 	top: 10%;
// 	left: @mobile-common-gutter;
// 	z-index: 2;

// 	font-size: 1.8em;
// 	font-size: 9vw;
// 	font-weight: bold;
// 	color: #FFF;
// 	text-shadow:
// 		0 0 0.7em fade(#000, 75%),
// 		0 0 0.5em fade(#000, 50%),
// 		0 .1em 0.25em fade(#000, 35%),
// 		0 1px 0 fade(#000, 35%);
// 	// @media (min-width: @screen-lg-min) {
// 	// 	font-size: 3em;
// 	// }
// }
// .home-hero-heading-second-line {
// 	display: block;
// 	margin-top: .25em;

// 	font-size: 0.5em;
// 	font-weight: normal;
// }

.home-hero-image-wrapper {
	z-index: 1;

	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}
.home-hero-image {
	display: block;
	max-width: 100%;
	height: auto;
}

// Caption
// .home-hero-caption-wrapper {
// 	padding: 15px 4% 50px;
// 	background-color: @premier-gray-darker;
// }
// .home-hero-caption {
// 	font-size: 14px;
// 	font-weight: 400;
// 	color: @global-text-color;
// 	line-height: 1.2em;
// }

.home-hero-carousel {
	width: 100%;
	position: relative;

	.hero-cell {
		width: 100%;
		// height: 100%;
		padding: 0;
		top: 0;

		background-color: #FFF;

		> a {
			color: #FFF;
			text-decoration: none;
		}
	}
	> .hero-cell {
		position: absolute;
		left: 0;

		&:first-of-type {
			z-index: 10;
			position: relative;
		}
	}

	.flickity-page-dots {
		position: relative;
		top: auto;
		bottom: auto;

		padding-top: 10px;
		padding-bottom: 10px;
		// border-top: 1px solid #000;

		.dot {
			width:  15px;
			height: 15px;
			margin: 0 9px;

			background-color: #BBB;
			opacity: 1;

			// States
			// --------------------------
			&:focus,
			&:hover {
				background-color: @link-color;
			}
			&.is-selected {
				background-color: @secondary-site-color;
				cursor: default;
			}
		}
	}

	.flickity-viewport {
		background-color: #CCC;
	}

	.flickity-prev-next-button {
		display: none;
	}
}

// hack for fixing an IE issue -- IE will generate a kind of fake image when using the lazyload method, which screws up the carousel height. cf https://github.com/metafizzy/flickity/issues/292
img[data-flickity-lazyload] { display: none; }


// Media queries for home hero related classes
@media (min-width: @screen-sm-min) {
	.home-hero {
		margin-left:  -(@common-gutter);
		margin-right: -(@common-gutter);
	}

	// .home-hero-inner {
	// 	overflow: hidden;
	// 	// height: 0;
	// 	// padding-top: 63%; // 0.6305555556 but manually floored down to avoid rounding errors in cropping
	// }

	// .home-hero-carousel {
	// 	height: 100%;
	// 	width: 100%;

	// 	.flickity-viewport {
	// 		background-color: transparent;
	// 	}
	// }

	// .home-hero-heading {
	// 	top: 16.5%;
	// 	// left: (@common-gutter * 2);

	// 	font-size: 2.5em;
	// 	font-size: 8vw;
	// }

	// .home-hero-image {
	// 	visibility: hidden;
	// }

	// .home-hero-caption-wrapper {
	// 	// position: absolute;
	// 	// bottom: 0;
	// 	// left: 0;
	// 	// right: 0;
	// 	// z-index: 2;

	// 	background-color: fade(#000, 33%);
	// 	color: #FFF;
	// 	min-height: 121px;
	// }
	// .home-hero-caption {
	// 	font-size: 15.5px;
	// 	color: #FFF;
	// }
}

// @media (min-width: @screen-md-min) {
// 	// .home-hero-heading {
// 	// 	font-size: 2.75em;
// 	// 	font-size: 8vw;

// 	// }

// 	// .home-hero-carousel {
// 	// 	//
// 	// 	.flickity-page-dots {
// 	// 		//
// 	// 		.dot {
// 	// 			width: 14px;
// 	// 			height: 14px;
// 	// 		}
// 	// 	}
// 	// }
// }

@media (min-width: @desktop-styles-min) {
	// .home-hero {
	// 	margin-left:  0;
	// 	margin-right: 0;
	// 	margin-bottom: @common-gutter;
	// 	top: 23%;
	// }
	// .home-hero-inner {
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	left: @navigation-desktop-width;
	// 	bottom: 0;

	// 	height: auto;
	// 	padding-top: 0;
	// }
	// .home-hero-carousel {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;

	// 	.hero-cell {
	// 		height: 100%;
	// 	}

	// 	.flickity-viewport {
	// 		height: 100% !important;
	// 	}
	// }

	// .home-hero-heading {
	// 	font-size: 2.5em;
	// 	font-size: 5.25vw;
	// }

	// .home-hero-image-wrapper {
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	left: 0;
	// }

	// .home-hero-caption-wrapper {
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// 	right: 0;
	// 	z-index: 2;

	// 	background-color: fade(#000, 33%);
	// 	color: #FFF;
	// 	min-height: 121px;
	// }
	// .home-hero-caption {
	// 	text-shadow:
	// 	0 0 0.7em fade(#000, 75%),
	// 	0 0 0.5em fade(#000, 50%),
	// 	0 .1em 0.25em fade(#000, 35%),
	// 	0 1px 0 fade(#000, 35%);
	// }


	// At desktop sizes, prevent default sliding transition and instead go with a fade transition
	.flickity-slider {
		.transform(translateX(0)) !important;
	}
	.hero-cell {
		position: absolute !important;
		left: 0 !important;
		opacity: 0;
		z-index: 1;
		.transition(opacity .40s ease);

		&.is-selected {
			opacity: 1;
			z-index: 2;
		}
	}
}

@media (min-width: @screen-lg-min) {
	.home-hero-carousel {
		//
		.flickity-prev-next-button {
			display: block;
			opacity: 0;
			.transition(all .15s ease);
		}

		&:hover {
			.flickity-prev-next-button {
				opacity: 1;
			}
		}
	}

	// .home-hero-heading {
	// 	font-size: 3em;
	// }
}

@media (min-width: (@screen-xl-min + 1)) {
	.home-hero {
		margin-left:  0;
		margin-right: 0;
	}
}


// Other home componenets
// ---------------------------------------
.home-callout-ads-wrapper {
	//
	@media (min-width: @screen-sm-min) {
		.callout-ad-listing {
			margin-left:  -(@common-gutter / 2);
			margin-right: -(@common-gutter / 2);
			margin-bottom: 0;
			margin-top: 0;
		}
		.callout-ad-listing__item {
			padding-left:  (@common-gutter / 2);
			padding-right: (@common-gutter / 2);
			margin-bottom: @common-gutter;

			img {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
