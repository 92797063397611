//
// Layout - Header
// --------------------------------------------------


// Header
// ---------------------------------------
.global-header {
	position: fixed;
	top: 0;

	background: @header-bg-color;
	background: linear-gradient(to bottom, lighten(@header-bg-color, 4%) 0%, @header-bg-color 50%, darken(@header-bg-color, 4%) 100%);

	// &.show-not-top {
	// 	background-color: fade(@header-bg-color, 90%);
	// }

	//   @the-transition: top .3s ease-out, background-color .4s ease-out;
	// .transition (@the-transition);

	.box-shadow(0 1px 3px 0 fade(#000, 25%));
}

.global-header-container {
	padding: 0;

	@media (min-width: @screen-sm-min) {
		padding-left:  @common-gutter;
		padding-right: @common-gutter;
	}
}


// Navigation Wrapper
// ---------------------------------------
.global-outer-navigation-wrapper {
	background-color: @navigation-bg-color;
}


// Logo
// ---------------------------------------
.global-logo {
	top: 13px;
	bottom: auto;

	max-width: 70%;
}
.global-logo-image {
	display: block;
	width: 300px;
	max-width: 100%;
}

.animated-responsive-menu-link {
	right: 0;
}

@media (min-width: @screen-sm-min) {
	.global-logo {
		top: @logo-margin;
		left: (@common-gutter);
	}
	.animated-responsive-menu-link {
		right: (@common-gutter / 2);
	}
}



// // Main Navigation
// // ---------------------------------------
// .global-main-nav {
// 	//
// }
// .global-main-menu {
// 	//
// }
// .global-nav-li {
// 	//
// }
.global-nav-link {
	line-height: 1.2;
	height: auto;
	padding-top:    0.8em;
	padding-bottom: 0.8em;

	// Account for the right button
	padding-right: (3.0em + 0.2);
	padding-right: (3.0rem + 0.2);
}

.global-nav-nonlink {
	padding-left: 3.2em;
	margin: 0!important;
    color: black!important;
    font-size: 13px!important;
    background-color: transparent!important;
    line-height: 1.2;
    height: auto;
    padding-bottom: 0;
    padding-top: 0;

	@media(min-width: 930px) {
    	margin-bottom: 5px!important;
		padding-left: 0!important;
	}

	&.bold {
		font-weight: bold!important;
		padding-top: 0.8em;
		font-size: 1.1em!important;
		padding-left: 2.5em;

    	@media(min-width: 930px) {
			padding-left: 0!important;
			margin-top: 15px!important;
			padding-top: 0;
		}
	}
}

// Responsive navigation open/close links
// --------------------------
.animated-responsive-menu-link {
	//
	&.is-active {
		background: @navigation-ui-active-fade;
	}
}


// Megamenus & Child Menus
// ---------------------------------------
.global-nav-li--has-megamenu,
.global-nav-li--has-child-menu {
	position: relative;
}

.global-nav-child-menu {
	height: 0;
	overflow: hidden;

	&.is-mobile-open {
		height: auto;
	}
}

.global-nav-list .global-nav-list {

	// Children _________
	> .global-nav-li {
		position: relative;

		// &:first-child {
		// 	border-top: 2px solid fade(#000, 30%);
		// }
		// &:last-child {
		// 	border-bottom: 2px solid fade(#000, 30%);
		// }
	}
	.global-nav-link {
		padding-left: 1.5em;

		// &:before {
		// 	content: "↳ ";
		// }
	}
}
.global-nav-list .global-nav-list .global-nav-list {
	height: 0;
	overflow: hidden;

	// Children _________
	.global-nav-link {
		padding-left: 2.5em;
	}

	// States _________
	&.is-mobile-open {
		height: auto;
	}
}

.submenu-is-mobile-open {
	background-color: @navigation-ui-active-fade;
}

// Button for opening/closing submenus
// --------------------------
.mobile-submenu-opener {
	position: absolute;
	top: 0;
	right: 0;

	width: 1.4em;
	width: 2.7rem;
	height: 1.4em;
	height: 2.7rem;
	padding: 0;
	border: 0;

	text-align: center;
	font-size: 2em;
	font-weight: bold;
	line-height: 1;
	background: @navigation-ui-active-fade;
	color: #FFF;

	// Children _________
	&:after {
		content: "▾";
		display: inline-block;
		margin-top: -0.5em;
	}

	// States _________
	&.is-active {
		background: fade(#000, 25%);

		&:after {
			.transform(rotate(180deg));
			.transform-origin(50% 50%);
		}
	}
}

// Megamenu-only content blocks
// --------------------------
.megamenu-content {
	// Hidden on mobile
	display: none;

	margin-right: @common-gutter;
}
.megamenu-promo {
	display: block;
}
.megamenu-promo-image {
	max-width: 100%;
	height: auto;
}



// Utility Navigation
// ---------------------------------------
.global-utility-nav {
	//
	> .container {
		padding-left: 0;
		padding-right: 0;
	}

	@media (min-width: @screen-sm-min) {
		> .container {
			padding-left: @common-gutter;
			padding-right: @common-gutter;
		}
	}
}
.global-utility-nav-link {
	padding: 0.5em 1.0em;
	//
	&:focus,
	&:hover {
		background-color: fade(#FFF, 15%); // @megamenu-bg-color; //fade(#000, 10%);
	}
}

.utility-cart-link,
.utility-order-link,
.utility-account-link {
	svg {
		width: 21px;
		// height: auto;
		vertical-align: top;
		margin-top: -2px;
		// margin-right: 0.25em;
		margin-left: -2px;

		#Fill-1,
		#Group {
			fill: @utility-nav-link-color;
		}
	}
}
.utility-order-link,
.utility-account-link {
	svg {
		width: 17px;
	}
}
.utility-cart-link,
.utility-order-link {
	svg {
		margin-top: -2px;
	}
}
.utility-order-link {
	svg {
		margin-left: 0;
		margin-top: -4px;
	}
}

.global-utility-child-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: left;

	a {
		color: #FFF;
		cursor: pointer;
		display: block;
		line-height: 1.2;
		padding: 0.5em 1.0em 0.5em 2.3em;
		text-decoration: none;
		white-space: nowrap;

		&:focus,
		&:hover {
			background-color: fade(#FFF, 15%);
		}
	}
}
@media (min-width: @desktop-styles-min) {
	.global-utility-child-list {
		background-color: lighten(desaturate(darken(@utility-nav-bg-color, 5%), 5%), 3%);
		display: none;
		left: 0;
		position: absolute;
		top: 100%;

		a {
			color: @utility-nav-link-color;
			line-height: inherit;
			padding: 0 1.25em 0.2em;

			&:hover,
			&:focus {
				background-color: fade(#000, 5%);
			}
		}
	}
	.global-utility-nav-li {
		position: relative;

		&:hover {
			.global-utility-child-list {
				display: block;
			}
		}
	}
}



// Search
// ---------------------------------------

.global-search-input {
	display: inline-block;
	vertical-align: middle;
}
.global-search-submit {
	display: inline-block;
	vertical-align: middle;

	font-size: 16px;
	@media (min-width: @screen-md-min) {
		font-size: 4vw;
	}
	@media (min-width: @screen-lg-min) {
		font-size: 39px;
	}
}

// .global-search-closer {
// 	top: -1em;
// }
