//
// Mixins
// --------------------------------------------------
// 1. basic "Function" mixins
// 2. multi-vendor-prefix mixins
// 3. more advanced Function mixins
// --------------------------------------------------


// 1. Basic "Function" mixins
// ---------------------------------------
.clear_float () {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

.prevent-bottom-margin-collapse() {
	&:after {
		content: "";
		display: table;
	}
}

.strip_list () {
	padding: 0;
	list-style-type: none;

	li {
		list-style: none;
	}
}

.hardware-accelerate () {
	// http://stackoverflow.com/questions/10014461/why-does-enabling-hardware-acceleration-in-css3-slow-down-performance?rq=1
		// prefixless because THANKS OBAMA/IE10
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000;
	perspective: 1000;
	// http://stackoverflow.com/a/26371807/4486857
		// "...Even if an element has been given its own layer, transitions on non-compositing properties (width, height, left, top, etc) still _cannot be accelerated_, because they occur before the compositing stage"
	.transform (translate3d(0,0,0));
}

.font-smoothing () {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.font-unsmoothing () {
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
}

.kern-me () {
	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	font-feature-settings: "kern" 1;
	font-kerning: normal;
}


// 2. Multi-prefix mixins
// ---------------------------------------
.box-sizing (@bsizing: border-box) {
	-webkit-box-sizing: @bsizing;
	   -moz-box-sizing: @bsizing;
	        box-sizing: @bsizing;
}
.box-shadow (@shadow: 0 2px 3px rgba(0,0,0,.3)) {
	-webkit-box-shadow: @shadow;
	        box-shadow: @shadow;
}
// No longer necessary, but keeping around as a mixin for a while for compatability's sake
.border-radius (@radius: 10px) {
	        border-radius: @radius;
}
.background-clip (@bgclip: padding-box) {
	-webkit-background-clip: @bgclip;
	        background-clip: @bgclip;
}
.transition (@transition: all 120ms ease-out) {
	-webkit-transition: @transition;
	        transition: @transition;
	// -webkit-font-smoothing: antialiased;
}
.transition-delay (@delay) {
	-webkit-transition-delay: @delay;
	        transition-delay: @delay;
}
.transform (@transformval) {
	-webkit-transform: @transformval;
	    -ms-transform: @transformval;
	        transform: @transformval;
}
.transform-origin (@transformval) {
	-webkit-transform-origin: @transformval;
	    -ms-transform-origin: @transformval;
	        transform-origin: @transformval;
}
.perspective-origin (@perspectiveval) {
	-webkit-perspective-origin: @perspectiveval;
	    -ms-perspective-origin: @perspectiveval;
	        perspective-origin: @perspectiveval;
}

// Animations
// --------------------------

.animation (@anim) {
	-webkit-animation: @anim;
	        animation: @anim;
}
.animation-name (@the-name: none) {
	-webkit-animation-name: @the-name;
	        animation-name: @the-name;
}
.animation-duration (@the-duration: 0s) {
	-webkit-animation-duration: @the-duration;
	        animation-duration: @the-duration;
}
.animation-timing-function (@the-timing-function: ease) {
	-webkit-animation-timing-function: @the-timing-function;
	        animation-timing-function: @the-timing-function;
}
.animation-delay (@the-delay: 0s) {
	-webkit-animation-delay: @the-delay;
	        animation-delay: @the-delay;
}
.animation-iteration-count (@the-iteration-count: 1) {
	-webkit-animation-iteration-count: @the-iteration-count;
	        animation-iteration-count: @the-iteration-count;
}
.animation-direction (@the-direction: normal) {
	-webkit-animation-direction: @the-direction;
	        animation-direction: @the-direction;
}
.animation-fill-mode (@the-fill-mode: none) {
	-webkit-animation-fill-mode: @the-fill-mode;
	        animation-fill-mode: @the-fill-mode;
}
.animation-play-state (@the-play-state: running) {
	-webkit-animation-play-state: @the-play-state;
	        animation-play-state: @the-play-state;
}

// Flexbox
// --------------------------

// Flex wrappers _________
.display-flex () {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
.display-inline-flex () {
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
}
.flex-direction (@the-direction: row) {
	    -ms-flex-direction: @the-direction;
	-webkit-flex-direction: @the-direction;
	        flex-direction: @the-direction;
}
.flex-wrap (@the-wrap: wrap) {
	    -ms-flex-wrap: @the-wrap;
	-webkit-flex-wrap: @the-wrap;
	        flex-wrap: @the-wrap;
}
.flex-justify-content (@the-justify: center) {
	          -ms-flex-pack: @the-justify;
	-webkit-justify-content: @the-justify;
	        justify-content: @the-justify;
}
.flex-align-items (@the-alignment: center) {
	     -ms-flex-align: @the-alignment;
	-webkit-align-items: @the-alignment;
	        align-items: @the-alignment;
}
.flex-align-content (@content-alignment: stretch) {
	   -ms-flex-line-pack: @content-alignment;
	-webkit-align-content: @content-alignment;
	        align-content: @content-alignment;
}

// Flex items _________
.order (@the-order: 1) {
	-ms-flex-order: @the-order;
	 -webkit-order: @the-order;
	         order: @the-order;
}
.flex (@the-flex: 1) {
	    -ms-flex: 1;
	-webkit-flex: 1;
	        flex: 1;
}
.flex-grow (@the-grow: 0) {
	-webkit-flex-grow: @the-grow;
	        flex-grow: @the-grow;
}
.flex-shrink (@the-shrink: 1) {
	-webkit-flex-shrink: @the-shrink;
	        flex-shrink: @the-shrink;
}
.flex-basis (@the-basis: auto) {
	-webkit-flex-basis: @the-basis;
	        flex-basis: @the-basis;
}
.flex-align-self (@the-alignment: auto) {
	-ms-flex-item-align: @the-alignment;
	 -webkit-align-self: @the-alignment;
	         align-self: @the-alignment;
}
// --------------------------
// end Flexbox mixins

.user-select (@select-value: none) {
	-webkit-user-select: @select-value;
	   -moz-user-select: @select-value;
	    -ms-user-select: @select-value;
	        user-select: @select-value;
}

// Calc mixin: https://gist.github.com/kbav/7891881
// usage example: ```.calc-width(~"100% - 95px");```
.calc-width (@expression) {
	width: -webkit-calc(@expression);
	width:         calc(@expression);
}



// Background clip for text mixin
// ---------------------------------------
// Based on Divya's pure CSS solution:
// http://nimbupani.com/using-background-clip-for-text-with-css-fallback.html
// ---------------------------------------
.clip-text-with-linear-gradient(@top-color: #000, @bottom-color: #555, @fallback-color: #000) {
	color: @fallback-color;
	-webkit-text-fill-color: transparent;
	background: -webkit-linear-gradient(@top-color, @bottom-color);
	background: -o-linear-gradient(@top-color, @bottom-color);
	-webkit-background-clip: text;
}
.clip-text-with-image(@img-url, @fallback-color: #000) {
	color: @fallback-color;
	-webkit-text-fill-color: transparent;
	background: -webkit-linear-gradient(transparent, transparent),
	            url(@img-url) repeat;
	background: -o-linear-gradient(transparent, transparent);
	-webkit-background-clip: text;
}


// LESS Quantity queries!
// ---------------------------------------
// quantity-queries.less
// https://github.com/adjohnson916/quantity-queries.less
// Concept: http://alistapart.com/article/quantity-queries-for-css
// ---------------------------------------
._build-quantity-selector(@selector-append, @selector-sibling) {
	&@{selector-append},
	&@{selector-append} ~ @{selector-sibling} {
		@rules();
	}
}
//
.exactly(@count, @selector-sibling, @rules) {
	@selector-append: e(":nth-last-child(@{count}):first-child");
	._build-quantity-selector(@selector-append, @selector-sibling);
}

.at-least(@count, @selector-sibling, @rules) {
	@selector-append: e(":nth-last-child(n+@{count})");
	._build-quantity-selector(@selector-append, @selector-sibling);
}

.at-most(@count, @selector-sibling, @rules) {
	@selector-append: e(":nth-last-child(-n+@{count}):first-child");
	._build-quantity-selector(@selector-append, @selector-sibling);
}

.between(@first, @last, @selector-sibling, @rules) {
	@selector-append: e(":nth-last-child(n+@{first}):nth-last-child(-n+@{last}):first-child");
	._build-quantity-selector(@selector-append, @selector-sibling);
}
// Usage example:
// --------------------------
// Note --->
// 1. If using a class, the element needs "escaped"
//    so as to not confuse LESS
// --------------------------
//
// // 6 elements or greater
// .foo {
//     .at-least(6, ~".foo", {
//         background: green;
//     });
// };
//
// // 2 elements or fewer
// .foo {
//     .at-most(2, ~".foo", {
//         background: yellow;
//     });
// };
//
// // exactly 7 elements
// .foo {
//     .exactly(7, ~".foo", {
//         background: orange;
//     });
// };
//
// // between 4 and 5 elements
// .foo {
//     .between(4, 5, ~".foo", {
//         background: red;
//     });
// };



// Scrolling shadows
// Inspired by http://lea.verou.me/2012/04/background-attachment-local/
// Cf. http://codepen.io/kbav/pen/QyVjRz/
// ---------------------------------------
   @scrolling-shadow-indication-shadow: fade(#000, 30%);
.scrolling-shadow-indication (@bgcolor:#FFF, @shadow: @scrolling-shadow-indication-shadow, @cover-size: 40px, @shadow-size: 12px) {

	@bgcolor-fade: fade(@bgcolor, 0%);

	// First two gradients are the shadow covers; second two are the shadows themselves
	background:
		-webkit-linear-gradient(@bgcolor 30%, @bgcolor-fade) 0 0~"/"100% @cover-size no-repeat local,
		-webkit-linear-gradient(@bgcolor-fade, @bgcolor 70%) 0 100%~"/"100% @cover-size no-repeat local,
		-webkit-linear-gradient(@shadow, rgba(0,0,0,0)) 0 0~"/"100% @shadow-size no-repeat scroll,
		-webkit-linear-gradient(rgba(0,0,0,0), @shadow) 0 100%~"/"100% @shadow-size no-repeat scroll;
	background:
		linear-gradient(@bgcolor 30%, @bgcolor-fade) 0 0~"/"100% @cover-size no-repeat local,
		linear-gradient(@bgcolor-fade, @bgcolor 70%) 0 100%~"/"100% @cover-size no-repeat local,
		linear-gradient(@shadow, rgba(0,0,0,0)) 0 0~"/"100% @shadow-size no-repeat scroll,
		linear-gradient(rgba(0,0,0,0), @shadow) 0 100%~"/"100% @shadow-size no-repeat scroll;
}
// Sample Usage
// .scrolling-shadows {
// 	.scrolling-shadow-indication (@tile-gray, rgba(0,0,0,0.25), 36px, 14px);
// }
