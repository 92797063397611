//
// Bulk Order page
// --------------------------------------------------


.empty-order-cart {
    .cart-head, .cart-foot {
        display: none;
    }
}

.enter-order-sku {
    padding-top: 1em;
    padding-bottom: 1em;

    > div {
        margin-bottom: 0.25em;
        vertical-align: top;
    }
}
.enter-sku-input,
.enter-qty-input {
    padding: 0.25em 0.5em 0.125em;
}
.enter-qty-input {
    min-width: 0;
    width: 3.4em;
}

.cart-table {
    .removing { // tr
        td {
            background-color: @wc-light-gray;
            color: lighten(@global-text-color, 20%);
        }
        img {
            opacity: 0.6 !important;
        }
        a {
            color: lighten(@global-text-color, 20%) !important;
            cursor: text;
            text-decoration: none !important;
        }
    }
}

.row-added {
    td {
        .animation(jump-link-target 2.0s ease-out);
    }
}
.row-error {
    td {
        .animation(error-flash 2.0s ease-out);
    }
}