//
// Animations
// --------------------------------------------------


// "Pulse" animation for focus assistance
// ---------------------------------------
// Thank you Lea! » http://lea.verou.me/2012/12/animations-with-one-keyframe/
// ---------------------------------------
@pulseSize: 1.05;

@-webkit-keyframes pulse {
	50% { -webkit-transform: scale( @pulseSize ); }
}
@keyframes pulse {
	50% { transform: scale( @pulseSize ); }
}


// Fade In Animation
// ---------------------------------------
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


// Fade Out Animation
// ---------------------------------------
@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


// Fade In & Up Animation
// ---------------------------------------
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-50px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}
@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}


// "Levitate" object animation
// ---------------------------------------
@-webkit-keyframes levitate {
	100% {
		-webkit-transform: translateY(-20%);
	}
}
@keyframes levitate {
	100% {
		transform: translateY(-20%);
	}
}


// Rotate full 360 degrees
// ---------------------------------------
@-webkit-keyframes full-rotation {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes full-rotation {
	100% {
		transform: rotate(360deg);
	}
}


// Jump link target animation
// ---------------------------------------
@-webkit-keyframes jump-link-target {
	0% {
		background-color: fade(@ui-highlight-color, 0%);
	}
	50% {
		background-color: @ui-highlight-color;
	}
	100% {
		background-color: fade(@ui-highlight-color, 0%);
	}
}
@keyframes jump-link-target {
	0% {
		background-color: fade(@ui-highlight-color, 0%);
	}
	50% {
		background-color: @ui-highlight-color;
	}
	100% {
		background-color: fade(@ui-highlight-color, 0%);
	}
}
