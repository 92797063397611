//
// Input Groups - Circa BS
// --------------------------------------------------

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;

    .input-field {
        float: left;
        margin-bottom: 0;
        position: relative;
        width: 100%;
        z-index: 2;

        &:focus {
            z-index: 3;
        }
    }
}

.input-group-addon,
.input-group-btn,
.input-group .input-field {
    display: table-cell;

    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
}

.input-group-addon,
.input-group-btn {
    vertical-align: middle;
    white-space: nowrap;
    width: 1%;
}

.input-group-addon {
    background-color: @input-bg;
    border: @input-border-width solid @input-border-color;
    border-radius: @input-border-radius;
    color: @input-color;
    font-size: @input-font-size;
    font-weight: normal;
    line-height: 1;
    padding: @input-padding;
    text-align: center;

    &:last-child {
        border-left: 0;
    }
    &:first-child {
        border-right: 0;
    }
}

.input-group .input-field:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group .input-field:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-btn {
    font-size: 0;
    position: relative;

    > .button {
        font-size: @input-font-size;
        height: 1.95em;
        line-height: 1.2;
        padding: 0.268em 0.7em 0.214em;

        + .button {
            margin-left: -1px;
        }

        &:hover,
        &:focus,
        &:active {
            z-index: 2;
        }
    }

    &:first-child {
        > .button {
            margin-right: -1px;
        }
    }

    &:last-child {
        > .button {
            z-index: 2;
            margin-left: -1px;
        }
    }
}