//
// Elements
// --------------------------------------------------



// Selection Colors
// ---------------------------------------
::-moz-selection { color: #000; background: #B4D5FF; text-shadow: none; }
::selection		 { color: #000; background: #B4D5FF; text-shadow: none; }
img::selection		{background: #B4D5FF; }
img::-moz-selection	{background: #B4D5FF; }
body {-webkit-tap-highlight-color: #5CA8FF; }



// Elements
// ---------------------------------------
/* Common margin-bottom for vertical rhythm. As per csswizardry.com/2012/06/single-direction-margin-declarations */
ul, ol, dd,
p, figure,
pre, table, fieldset, hr,
.media, .content-block {
	margin-bottom: 1em;
}



// Headings
// ---------------------------------------
h1, h2, h3, h4, h5, h6,
.like-h1, .like-h2, .like-h3, .like-h4, .like-h5, .like-h6 {
	// margin-bottom: .5em;
	font-family: @headings-font-family;
	font-weight: @headings-font-weight;
	line-height: @headings-line-height;
	color: @headings-color;

	.font-smoothing ();
	.kern-me ();
}
h1, .like-h1 {
	margin: 0 0 0.52em 0;

	font-size: 2.35em;
	font-size: 2.35rem;
}
h2, .like-h2 {
	margin: 0 0 0.635em 0;

	font-size: 1.75em;
	font-size: 1.75rem;
}
h3, .like-h3 {
	margin: 0 0 0.817em 0;

	font-size: 1.4em;
	font-size: 1.4rem;
}
h4, .like-h4 {
	margin: 0 0 1.143em 0;

	font-size: 1.2em;
	font-size: 1.2rem;
}
h5, .like-h5 {
	margin: 0 0 1.429em 0;

	font-size: 1.0em;
	font-size: 1.0rem;
}
h6, .like-h6 {
	margin: 0 0 1.681em 0;

	font-size: 0.85em;
	font-size: 0.85rem;
}



// Figures
// ---------------------------------------
// figure {
// 	//
// }
figcaption {
	padding: 0 5%;
	font-size: 0.8em;
	color: #777;
	color: lighten(@global-text-color, 10%);
}



// Anchors
// ---------------------------------------
a {
	color: @link-color;
	text-decoration: none;

	.transition (all 150ms ease-out);

	// &:link {}
	// &:visited {
	// 	color: desaturate(@link-color, 100%);
	// }
	&:focus,
	&:hover {
		color: @link-highlight;
		text-decoration: underline;
	}
	&:active {
		color: @link-shade;
	}
	// &:hover:focus {}
	// &:hover:active {}
}


// Alternate link colors
// --------------------------
.alt-link-color {
	color: @alt-link-color;
	&:focus,
	&:hover {
		color: @alt-link-highlight;
	}
	&:active {
		color: @alt-link-shade;
	}
}
.light-link-color {
	color: @light-link-color;
	&:focus,
	&:hover {
		color: @light-link-highlight;
	}
	&:active {
		color: @light-link-shade;
	}
}
.grayed-link-color {
	color: @grayed-link-color;
	&:focus,
	&:hover {
		color: @grayed-link-highlight;
	}
	&:active {
		color: @grayed-link-shade;
	}
}


// Link surrounding image
// --------------------------
a img {
	border: 0;
	.transition(opacity 0.12s ease);

	&:hover {
		border: 0;
		opacity: .8;
	}
}



// Paragraphs
// ---------------------------------------
p {
	//

	// child-controls
	&:first-child { margin-top: 0; }
	// &:last-child  { margin-bottom: 0; }
}



// Horizontal rules
// ---------------------------------------
hr {
	height: 1px;
	border: 0;
	margin: 1em 0;

	background: #bbb;
	background: rgba(0,0,0,.27);
}



// Tables
// ---------------------------------------
table {
	border-top:    1px solid @table-border-color;
	border-left:   1px solid @table-border-color;
}

th,
td {
	padding: 5px;
	border-right:  1px solid @table-border-color;
	border-bottom: 1px solid @table-border-color;
	vertical-align: top;
}

th {
	background-color: #ddd;
	background-color: fade(#000, 15%);
}


// Table extensions / customization
// --------------------------
.table--striped {
	tbody tr:nth-child(even) {
		background-color: rgba(0,0,0,.065);
	}
}

.table--borderless {
	border: 0;

	th,
	td {
		border: 0;
	}
}



// Lists
// ---------------------------------------
menu, ol, ul {
	padding: 0 0 0 2em;
}

li > ul,
li > ol {
	margin-bottom: 0;
}



// Code
// ---------------------------------------
code,
pre {
	padding: 0 3px 2px;
	border-radius: 3px;

	font-family: @font-family-code;
	color: #333333;
}

code {
	display: inline-block;
	padding: 2px 4px;
	border: 1px solid #e1e1e8;

	color: #d14;
	background-color: #f7f7f9;
	// white-space: nowrap;
}

pre {
	display: block;
	padding: 9.5px;
	margin: 0 0 10px;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;

	background-color: #f5f5f5;

	word-break: break-all;
	word-wrap: break-word;
	white-space: pre;
	white-space: pre-wrap;
}
pre.prettyprint {
	margin-bottom: @common-gutter;
}
pre code {
	padding: 0;
	border: 0;

	background-color: transparent;

	color: inherit;
	white-space: pre;
	white-space: pre-wrap;
}
.pre-scrollable {
	max-height: 300px;
	overflow-y: auto;
}

kbd {
	display: inline-block;
	padding: 0.1em 0.35em;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin: 0 0.2em;

	background-color: #EFEFEF;
	box-shadow:
		0 1px 1px rgba(0, 0, 0, 0.25),
		inset 0 0 0 2px #fff;
}
