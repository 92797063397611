//
// Components
// --------------------------------------------------


// Misc. Elemental Components
// ---------------------------------------

// Horizontal Rules
// --------------------------
hr.primary {
	height: 3px;
	background-color: @secondary-site-color;
}


// Blockquotes
// --------------------------
blockquote {
	padding: 1em 10%;
	border-top: 2px solid #8f5945;
	border-bottom: 2px solid #8f5945;

	font-size: 1.2em;

	> :last-child {
		margin-bottom: 0;
	}

	@media (min-width: @screen-lg-min) {
		font-size: 1.4em;
	}
}



// Forms
// ---------------------------------------
select.input-field {
	height: 1.9em;
}// Announcement

.horizontal-inputs label {
	margin-right: 0.5em;
}
.label-contents img {
	display: block;
}
li.parsley-required {
	color: @error-color;
	// Syntax: <name> || <time> || <timing-function> || <delay> || <iteration-count> || <direction> || <fill-mode>
	.animation (pulse .3s ease 0s 1 normal both);
}
.parsley-success {
	background-color: @input-bg !important;
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:active {
	@the-shadow:
		0 .2em .5em rgba(0,0,0,.13) inset,
		0 0 0 100px fade(#FFF, 50%) inset;
	.box-shadow (
		@the-shadow;
	);
}
:-webkit-autofill:focus {
	@the-shadow:
		0 .2em .5em fade(#000, 9%) inset,
		0 0 0 100px fade(#FFF, 50%) inset,
		0 0 .5em .1em fade(@focus-highlight-color, 75%);
	.box-shadow (
		@the-shadow;
	);
}



// Tabs
// ---------------------------------------
.tab-content > .tab-pane {
	display: none;
}
.tab-content > .active {
	display: block;
}



// Announcements
// ---------------------------------------
.announcement {
	background: @wc-light-green;
	//
	.container {
		// padding-top: 10px;
		// padding-bottom: 10px;

		// background: linear-gradient(to bottom, darken(@wc-light-tan, 7%) 0%, @wc-light-tan 100%);
		// text-align: center;

		@media (min-width: (@screen-xl-min + 1)) {
			max-width: (@site-max-width - @grid-gutter-width);
		}
		@media (min-width: (@screen-xl-min + @grid-gutter-width)) {
			max-width: (@site-max-width);
		}
	}
}


// listpage hero
.listpage-hero-container {
	display: grid;
	grid-template-columns: 175px 1fr;
}
.listpage-hero-image {
	width: 200px;
	height: 200px;
	object-fit: contain;
	padding: 30px;
}
.listpage-hero-description {
	font-size: 1vw;
	padding: 20px;
	position: relative;
}
.listpage-hero-description p {
	position:absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	margin: auto;
	height: 50%; /*requires explicit height*/
}
@media only screen and (max-width: 1200px) {
	.listpage-hero-description {
		font-size: 12px;
	}
}
@media only screen and (max-width: 900px) {
	.listpage-hero-container {
		grid-template-columns: 100px 1fr;
		text-align: center;
	}
	.listpage-hero-image {
		padding: 10px;
		width:100%
	}
	.listpage-hero-description {
		text-align: left;
		font-size: 12px;
		padding: 10px;
	}
}


// Breadcrumbs
// ---------------------------------------

// Variables
// --------------------------
// @breadcrumbs-wrapper-margin: 			auto;

@breadcrumbs-font-size: 				0.9em;
// @breadcrumbs-margin-bottom: 			(@common-gutter / 2);

// @breadcrumbs-link-color: 				inherit;

// @breadcrumbs-divider-character: 		"\00BB";
// @breadcrumbs-divider-font-size: 		1.25em;
// @breadcrumbs-divider-font-weight: 		normal;
@breadcrumbs-divider-color: 			#ceafa1;
// --------------------------




// Panels
// ---------------------------------------
.panel {
	.island;
	padding-bottom: 0.01px;
	// padding: @common-gutter;

	// border-top:    1px solid #000;
	// border-bottom: 1px solid #000;
}
.panel--gray {
	background-color: @panel-color-gray;
	// background: linear-gradient(to top, darken(@panel-color-gray, 7%) 0%, @panel-color-gray 100%);
}
// .panel--tan {
// 	background-color: @panel-color-tan;
// 	background: linear-gradient(to top, darken(@panel-color-tan, 7%) 0%, @panel-color-tan 100%);
// }

// Larger panel as used on home page
.callout-panel {
	padding-top: 0;
	margin-left:  -(@mobile-container-gutter);
	margin-right: -(@mobile-container-gutter);

	&.panel--white {
		border-top: 1px solid fade(@wc-brown, 40%);
	}

	.see-more-link {
		float: right;
	}
}

.callout-panel-heading {
	padding-top: @common-gutter;
	padding-bottom: 0.635em;
	padding-left:  (@common-gutter / 2);
	padding-right: (@common-gutter / 2);
	// margin-top: -(@common-gutter);
	margin-bottom: 0;

	background-repeat: no-repeat;

	text-align: center;
	font-family: @font-family-sans;
	font-size: 1.6em;
	font-weight: lighter;
	text-transform: uppercase;
	color: @wc-brown;
	// text-shadow:
	// 	0 1px 0 #FFF,
	// 	0 0 1em #FFF;


	// Variants
	// .panel--tan & {
	// 	color: darken(@panel-color-tan, 45%);
	// }

	@media (min-width: @screen-md-min) {
		font-size: 2.2em;
	}
}
.callout-panel-heading--reversed {
	margin-bottom: @common-gutter;

	background: @wc-green;
	color: #FFF;
}
// Extensions
// --------------------------

	// Mixin for radial glow making...
	// .radial-top-glow(@panel-heading-bg-color: #FFF) {
	// 	background: -moz-radial-gradient(top, farthest-side, fade(@panel-heading-bg-color, 100%) 0%, fade(@panel-heading-bg-color, 0%) 100%);
	// 	background: -webkit-radial-gradient(top, farthest-side, fade(@panel-heading-bg-color, 100%) 0%, fade(@panel-heading-bg-color, 0%) 100%);
	// 	background: radial-gradient(farthest-side at 50% top, fade(@panel-heading-bg-color, 100%) 0%, fade(@panel-heading-bg-color, 0%) 100%);
	// }

// .callout-panel-heading--white-glow {
// 	@panel-heading-bg-color: #FFF;

// 	.radial-top-glow(@panel-heading-bg-color);
// }
// .callout-panel-heading--green-glow {
// 	@panel-heading-bg-color: lighten(@wc-green, 30%);

// 	.radial-top-glow(@panel-heading-bg-color);
// }
// .callout-panel-heading--tan-glow {
// 	@panel-heading-bg-color: lighten(@wc-yellow, 30%);

// 	.radial-top-glow(@panel-heading-bg-color);
// }

@media (min-width: @screen-sm-min) {
	.callout-panel {
		margin-left:  -(@common-gutter);
		margin-right: -(@common-gutter);
	}
}
@media (min-width: @screen-lg-min) {
	.callout-panel {
		// padding-left: (@common-gutter * 2);
		// padding-right: (@common-gutter * 2);
		padding-bottom: @common-gutter;
		margin-bottom: (@common-gutter * 2);
	}
}
@media (min-width: (@screen-xl-min + 1)) {
	.callout-panel {
		padding-left: 0;
		padding-right: 0;

		margin-left:  0;
		margin-right: 0;
	}
}


// Callout Panel: About this Artisan
// --------------------------
.about-the-creator {
	// .row {
	// 	max-width: 1080px;
	// 	margin-left: auto;
	// 	margin-right: auto;
	// }

	// Children _________
	.callout-panel-heading {
		padding-top: 0;
		padding-bottom: 0;
		margin: @common-gutter 0;
	}

	> .row {
		margin-top: @common-gutter;
	}

	.creator-image {
		display: block;
		max-height: 17em;
		margin: auto auto 1em;
	}


	.creator-blurb {
		margin-bottom: 1em;

		:last-child {
			margin-bottom: 0;
		}
	}

	// Contextual Parentage _________
	.creator-list & {
		margin-bottom: @common-gutter;

		.creator-heading {
			font-size: 1.6em;
			font-size: 1.6rem;
			color: @secondary-site-color;
		}
	}

	// Center the text block if flexbox is supported
	@media (min-width: @screen-md-min) {
		.mod-flexbox & .row {
			.display-flex();
			.flex-align-items(center);
		}
		.creator-image {
			margin: auto;
		}
	}
}


// Product Listing
// ------------------------------------
// .product-listing-wrapper {
// 	//
// }
.product-listing {
	//
}
.product-listing__item {
	// Fix listing specificity, since the media query collection/combination gulp task makes BS3 column classes' selectors appear _after_ my declarations
	&[class*="col-"] {
		float: none;
	}

	margin-bottom: (@common-gutter / 2);
}
.product-listing__link {
	display: block;
	border: 1px solid transparent;

	text-align: center;
	text-decoration: none !important;

	// // Loading & animations -----------
	// .mod-cssanimations & {
	// 	opacity: 0;
	// 	.animation (fadeIn 0.375s ease 0s 1 normal both);
	// }

	// // Nth Child-controlled animations
	// @base-delay: 	0.150s;
	// @delay-delta: 	0.045s;

	// // For 9 and greater, use the same delay
	// .product-listing__item:nth-child(n + 8) > & {
	// 	@the-child: 9;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(2n + 8) > & {
	// 	@the-child: 10;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(3n + 8) > & {
	// 	@the-child: 11;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// // Specific times for
	// .product-listing__item:nth-child(1) > & {
	// 	@the-child: 1;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(2) > & {
	// 	@the-child: 2;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(3) > & {
	// 	@the-child: 3;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(4) > & {
	// 	@the-child: 4;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(5) > & {
	// 	@the-child: 5;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(6) > & {
	// 	@the-child: 6;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(7) > & {
	// 	@the-child: 7;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }
	// .product-listing__item:nth-child(8) > & {
	// 	@the-child: 8;
	// 	.animation-delay(@base-delay + @delay-delta * @the-child);
	// }

	// Interactions -----------
	&:focus,
	&:hover {
		background: darken(#FFF, 3%);
		border-color: @input-border-color;
		// @the-shadow: 0 0 1em fade(@input-border-color, 25%);
		// .box-shadow (@the-shadow);

		// Animation test...
		// .product-listing__photo {
		// 	// Syntax: <name> || <time> || <timing-function> || <delay> || <iteration-count> || <direction> || <fill-mode>
		// 	.animation (pulse .3s ease 0s 1 normal forwards);
		// }

		// Hover scaling test...
		.transform( scale(1.05) );
		position: relative;
		z-index: 2;
		@the-shadow:
			0 0 1em fade(@input-border-color, 50%),
			0 0 2em fade(@input-border-color, 25%),
			0 0 3em fade(@input-border-color, 25%);
		.box-shadow (@the-shadow);

		// Adjusted anchor transition timing: allow for a delay when hovering over items
		.transition(all .15s ease-in .05s);

		.product-listing__product-title {
			color: @link-color;
		}
	}
}

// .product-listing__photo-wrapper {
// 	// overflow: hidden;
// }
.product-listing__photo {
	display: block;
	max-width: 100%;
	height: auto;
	margin-left:  auto;
	margin-right: auto;
	margin-bottom: (@common-gutter / 2);
	opacity: 1 !important;
}

.product-listing__product-title {
	padding: 0 0.715em;
	margin-bottom: (@common-gutter / 4);

	font-size: 14px;
	font-weight: bold;
	color: @global-text-color;
}
.product-listing__price {
	margin-bottom: (@common-gutter / 4);
	font-size: 14px;
	color: @global-text-color;
}
.product-listing__rating {
	margin-bottom: (@common-gutter / 2);
}


.product-listing__description {
	display: none;
}



// Product Quantity Input
// ------------------------------------
.product-quantity-input {
	// Padding "reset" of sorts for these
	padding: 0.25em 0.5em 0.125em;
	width: 3.4em;
	min-width: 0;
}



// Detailed Product List (Vertical, divided)
// ------------------------------------
.detailed-product-list {
	list-style: none;
	padding: 0;
	// border-top: 1px solid @line-divider-color;
	margin: 0 0 1em 0; // 1em 0;

	// Children
	.product-photo-wrapper {
		display: block;
	}
	.product-photo {
		display: block;
		max-width: 100%;
		height: auto;
	}

	.product-category {
		display: block;
		margin-bottom: 1em;

		font-size: .875em;
		font-weight: normal;
	}
	.product-title {
		margin-bottom: .25em;
		font-size: 1.1em;
		font-weight: bold;
	}
	// .product-sku-and-more {
	// 	// margin-bottom: .5em;

	// 	font-size: 0.875em;

	// 	// .product-stock-message {
	// 	// 	margin-bottom: 0;
	// 	// }
	// }
	// .product-stock-message {
	// 	// font-size: 0.9375em;
	// 	// font-style: italic;
	// }
	.product-summary {
		font-size: .875em;
	}

	.product-price {
		margin-bottom: (@common-gutter / 2);

		text-align: center;
		font-size: 2em;
		line-height: 1.35;
		// font-weight: bold;
	}
	.product-quantity-input-label {
		margin-bottom: 1em;
		text-align: center;
	}
	.label-contents {
		// display: inline-block;
		// font-size: .9em;

		abbr {
			border-bottom: 0;
		}
	}
	.product-quantity-input {
		width: 3.4em;
		min-width: 0;
	}
	// .product-actions {
	// 	text-align: right;

	// 	> .button {
	// 		padding: 0 .5em;
	// 		font-size: .75em;
	// 	}
	// }
}
// .detailed-product-list-item {
// 	// padding: @common-gutter 0 1px;
// 	// border-bottom: 1px solid @line-divider-color;
// }



// Product Variation listing
// ---------------------------------------
// .variations-form,
// .product-variation-list {
// 	clear: both;
// 	list-style: none;
// }
// // .product-variation-list {
// // 	//
// // }
// .inset-variations {
// 	margin-bottom: 30px;

// 	.product-actions-block {
// 		margin-bottom: 10px;
// 	}

// 	.product-variation-list {
// 		border: 0;
// 		margin-left: -15px;
// 		margin-right: -15px;

// 		@media (min-width: @screen-sm-min) {
// 			margin-left: -20px;
// 			margin-right: -20px;
// 		}
// 	}
// 	.product-variation {
// 		border: 0;
// 		padding-left: 20px;
// 		padding-right: 20px;

// 		&:nth-of-type(odd) {
// 			background: #FEFAE8;
// 		}
// 	}
// }
.product-variation-selection {
	//
	.format-type {
		display: block;
		font-size: 1.25em;
		font-weight: bold;
	}
	.product-actions-block {
		margin-top: 0.5em;

		@media (min-width: @screen-lg-min) {
			margin-top: 0;
		}
	}
}



// // Article Listing
// // --------------------------------------
// // .article-listing-wrapper {}
// .article-listing {
// 	list-style: none;
// 	padding: 0;
// 	margin: 0;
// }
// .article-listing-li {
// 	list-style: none;

// 	padding-left:  (@common-gutter / 4);
// 	padding-right: (@common-gutter / 4);
// 	margin-bottom: 1.6em;

// 	@media (min-width: @screen-sm-min) {
// 		margin-bottom: 1.25em;
// 	}
// 	@media (min-width: @desktop-styles-min) {
// 		padding-left:  (@common-gutter / 2);
// 		padding-right: (@common-gutter / 2);
// 	}
// }
// .article-listing__item {
// 	font-size: 0.9em;

// 	.article-photo {
// 		display: block;
// 		margin-bottom: 1em;
// 	}

// 	& > p:last-child {
// 		margin-bottom: 0;
// 	}
// }
// .article-listing-heading {
// 	font-size: 1.1em;
// 	font-weight: bold;
// 	.font-unsmoothing();
// }

// @media (min-width: @screen-sm-min) {
// 	.article-listing {
// 		margin-left: 5px;
// 		margin-right: 5px;
// 	}
// }
// @media (min-width: @desktop-styles-min) {
// 	.article-listing {
// 		margin-left: 10px;
// 		margin-right: 10px;
// 	}
// }
// @media (min-width: @site-max-width) {
// 	.article-listing {
// 		margin-left:  -(@common-gutter / 2);
// 		margin-right: -(@common-gutter / 2);
// 	}
// }



// Callout Ad Listing
// ---------------------------------------
// .callout-ad-listing {}
// .callout-ad__item {}
.callout-ad__link {
	//
	> img {
		margin-right: auto;
		margin-left: auto;
	}
}



// Link Enhancers
// --------------------------------------------------
.link-indicator {
	position: relative;
	display: inline-block;
	// vertical-align: top;
	font-size: 1.35em;
	line-height: 1;

	.transition(all .12s ease);


	a:hover & {
		.transform(translateX(.125em));
	}
}



// Word dividers ( ---- OR ---- )
// ---------------------------------------
.word-divider {
	position: relative;
	display: block;
	margin: 1em auto;

	background: linear-gradient(to bottom,
		fade(#000, 0%) 0%,
		fade(#000, 0%) 49%,
		@line-divider-color 49%,
		@line-divider-color 51%,
		fade(#000, 0%) 51%,
		fade(#000, 0%) 100%);
	text-align: center;

	.the-words {
		display: inline-block;
		padding: 0 0.5em;
		border: 1px solid @line-divider-color;
		background-color: #FFF;
	}
}



// Product information table
// ---------------------------------------
.product-information-table {
	.table--striped;
	// width: 100%;
	min-width: 62%;
	margin-bottom: @common-gutter;
	border: 0; //1px solid #ccc;

	background: @panel-color-gray;
	font-size: 0.8em;

	th,
	td {
		padding: 5px 10px;
		border: 0;

		text-align: left;
	}

	th {
		min-width: 10em;
		background-color: transparent;
	}

	.product-meta-link {
		display: block;
	}
}



// Hoverbuttons
// ---------------------------------------
.hoverbutton {
	display: inline-block;
	height: 1.75em;
	line-height: 1.75;
	padding: 0 0.5em;
	border-radius: @button-border-radius;

	text-decoration: none !important;

	&:focus,
	&:hover {
		background-color: @link-color;
		color: @button-text-color;
	}
}



// Full page menu
// ---------------------------------------
.full-page-menu {
	padding-top: -webkit-calc(~"@{header-mobile-height} + 10vh");
	padding-top:         calc(~"@{header-mobile-height} + 10vh");
	// margin-top: @header-mobile-height;

	background-color: @full-page-menu-bg-color;

	@media (min-width: @desktop-styles-min) {
		padding-top: -webkit-calc(~"@{header-height} + 10vh");
		padding-top:         calc(~"@{header-height} + 10vh");
		// margin-top: @header-height;
	}
}



// Page Styles
// ---------------------------------------
.page-main-heading {
	margin-bottom: (@common-gutter / 2);

	font-size: 1.65em;
	font-size: 1.65rem;
	letter-spacing: -0.0125em;
	color: @secondary-site-color;

	@media (min-width: @screen-md-min) {
		margin-bottom: @common-gutter;

		font-size: 2.1em;
		font-size: 2.1rem;
	}
	@media (min-width: @screen-lg-min) {
		font-size: 2.8em;
		font-size: 2.8rem;
	}

	// Larger sizes when using the intended TypeKit font
	.wf-active & {
		font-size: 1.65em;
		font-size: 1.65rem;

		@media (min-width: @screen-md-min) {
			font-size: 2.1em;
			font-size: 2.1rem;
		}
		@media (min-width: @screen-lg-min) {
			font-size: 2.8em;
			font-size: 2.8rem;
		}
	}
}

.page-intro-copy {
	font-size: 1.25em;
}


// Pagination
// ---------------------------------------
.pagination-progress-indicator {
	margin-bottom: (@common-gutter / 2);

	font-size: 0.80em;
	color: lighten(@global-text-color, 10%);
}



// Cart Table
// --------------------------------------
@cart-table-thead-bg-color: 		#EEE;
@cart-table-tfoot-bg-color: 		#EEE;
@cart-table-border-color: 			@line-divider-color;
@cart-table-product-photo-size: 	65%;

.cart-table {
	width: 100%;
	border: 1px solid @cart-table-border-color;

	font-family: @font-family-base;


	// All cells
	// -----------------------------
	th,
	td {
		padding: 0;
		border: 0;
		border-bottom: 1px solid @cart-table-border-color;

		text-align: left;
	}


	// thead and tfoot cells
	// -----------------------------
	th,
	tfoot td {
		vertical-align: middle;

		background: @cart-table-thead-bg-color;
		font-weight: bold;
		text-transform: uppercase;
	}
	th {
		padding: (@common-gutter / 2);
		font-size: 0.875em;
	}
	tfoot td {
		padding: (@common-gutter / 2);
		font-size: 0.85em;
	}

	.cart-table-footer-row__discount {
		//
		> .price-total-column {
			color: #06af08;
		}
	}
	// .cart-table-footer-row__subtotal {
	// 	//
	// }
	// .cart-table-footer-row__shipping {
	// 	//
	// }
	// .cart-table-footer-row__taxes {
	// 	//
	// }
	.cart-table-footer-row__total {
		//
		> td {
			background-color: lighten(@primary-site-color, 95%, relative);
			font-size: 1em;
		}
	}


	// tbody cells
	// -----------------------------
	tbody td {
		padding: @common-gutter (@common-gutter / 2);
	}


	// Pricing columns
	// -----------------------------
	.price-each-column,
	.price-total-column {
		min-width: 6em;
		padding-left: (@common-gutter / 2);
		border-left: 1px solid @cart-table-border-color;

		text-align: right;
	}
		// tfoot .price-total-column {
		// 	border-left: 0;
		// }


	// Namespaced styling for common cart-table child product listing elements
	// -----------------------------
	.product-photo-column {
		padding-right: 0;
	}
	.product-photo-wrapper {
		display: block;
	}
	.product-photo {
		display: block;
		width: 100%;
		margin: auto;
	}

	.product-title {
		margin-bottom: 0.15em;

		font-size: 1.4em;
	}
	.product-sku-and-more {
		margin-bottom: 1em;
	}

	// .product-quantity-update {
	// 	// margin-bottom: 1em;
	// }
	.product-quantity-input-label {
		display: inline-block;

		margin-bottom: 1em;
	}
	.product-quantity-input {
		width: 3.4em;
		min-width: 0;
		padding: 0.25em 0.5em 0.125em;
		margin-right: 1em;
	}
	.product-quantity-update-button {
		vertical-align: top;
		margin-bottom: 1em;
	}

	.product-actions {
		font-size: 0.875em;

		> a {
			font-weight: normal;
		}
	}


	// Mobile version
	// -----------------------------
	@media (max-width: @screen-sm-max) {

		font-size: 0.875em;

		// Column adjustments
		thead {
			display: none;
		}
		td {
			display: block;
			padding: (@common-gutter / 2) 0;
		}
		tbody .description-and-modify-column {
			border-bottom: 1px dashed @line-divider-color;
		}
		tbody .price-each-column {
			padding: (@common-gutter / 2);
			border-left: 0;
			border-bottom: 1px dashed @line-divider-color;

			&:before {
				content: "Price each";

				font-weight: bold;
				text-transform: uppercase;
			}
		}
		tbody .price-total-column {
			padding: (@common-gutter / 2);
			border-left: 0;

			&:before {
				content: "Price total";

				font-weight: bold;
				text-transform: uppercase;
			}
		}

		tfoot .description-and-modify-column,
		tfoot .price-each-column {
			display: none;
		}
		tfoot .price-total-column {
			border-left: 0;
			font-size: 1.286em;

			&:before {
				content: "Subtotal";

				display: inline-block;
				margin-right: .25em;
				font-weight: bold;
				text-transform: uppercase;
			}

			&.price-freight-column:before 		{ content: "Freight"; }
			&.price-taxes-column:before 		{ content: "Taxes"; }
			&.price-full-total-column:before 	{ content: "Total Order"; }
			&.price-discount-column:before 	    { content: "Promotional Savings"; }
			&.price-shipping-column:before 	    { content: "Shipping & Processing"; }
			&.price-rush-shipping-column:before { content: "Rush Delivery"; }
			&.price-donation-column:before      { content: "Donation"; }
		}

		.product-photo-column {
			padding-left: 0;
		}
		.product-photo {
			width: @cart-table-product-photo-size;
			max-width: 380px;
			margin-bottom: 1em;
		}

		.product-actions {
			font-size: 1em;
		}

		.the-price {
			display: inline-block;
			// width: 70px;
			// width: 4.2rem;
			padding-left: .5em;
		}
	}
}

// .cart-actions-block {
// 	//
// }
.cart-actions-column {
	margin-bottom: 1em;
}
.checkout-submit-button {
	// max-width: 380px;
	font-weight: bold;
	text-transform: uppercase;
}

.product-kit-list {
	display: table;

	font-size: 0.875em;

	> li {
		display: table-row;
	}

	.quantity,
	.id,
	.description {
		display: table-cell;
	}

	.quantity {
		padding-right: 0.5em;
	}

	.description {
		&:before {
			content: "–";
			display: inline-block;
			margin: auto 0.25em;
		}
	}
}

// .product-customization-input {
// 	max-width: 15em;
// }

// Extensions
// --------------------------
.cart-table--summary {
	max-width: 45em;
	border: 1px solid @cart-table-border-color;

	.product-title {
		font-size: 1.20em;
	}
}

// .shopping-cart-section {
// 	// max-width: @screen-md-max; // 60em;
// 	// margin-left: auto;
// 	// margin-right: auto;
// }

.form-section {
	margin: 1em 0;

	// background-color: #DDD;
}



// Auto Grid Layout Table for WMU layout
// ---------------------------------------
.table--auto-grid-layout {
	//
	td {
		display: inline-block;
		vertical-align: top;
		margin-right: -.25em;
		width: 50%;
	}

	@media (min-width: @screen-md-min) {
		td {
			width: 33.333%;
		}
	}
	@media (min-width: @screen-lg-min) {
		td {
			width: 25%;
		}
	}
}



// Required Symbol
// ---------------------------------------
.required-symbol {
	top: 0;

	font-size: 1.25em;
}




// Loading Spinner
// ---------------------------------------

// Customization
// --------------------------
@loading-spinner-size: 			22px;
@loading-spinner-line-width: 	4px;
@loading-spinner-color: 		#BBB;
@loading-spinner-frequency: 	1.15s;
// --------------------------



// OOCSS
// ---------------------------------------

// Media Object
// --------------------------
.media {margin:10px;}
.media, .bd {overflow:hidden;}
.media .img {float:left; margin-right: 10px;}
.media .img img{display:block;}
.media .imgExt{float:right; margin-left: 10px;}



// Misc pieces for Mully
// ---------------------------------------
.collapsed-content-margins {
	margin-bottom: 1.5rem;

	h2,
	h3,
	p {
		margin-top: 4px;
		margin-bottom: 7px;
	}
}

.dib-row .column {
	float: none;
	display: inline-block;
	margin-right: -.25em !important;
	vertical-align: top;

	overflow: hidden;
}
/*.mully-share-image {
	float: left;
	width: 33%;
}*/


.media--mully-social {
	margin: 0 0 1rem;

	.img {
		display: block;
		width: 33%;
	}
	.bd {
		margin-top: 0;
		font-size: .85em;
	}
	@media (min-width: 400px) and (max-width: 666px) {
		.bd {
			font-size: 1em;
		}
	}
	@media (min-width: 950px) {
		.bd {
			font-size: 1em;
		}
	}
}


.newsletter-miniform-wrapper {
	width: 360px;
	max-width: 100%;
	padding: @common-gutter;

	background-color: #F3F3F3;
}
// .newsletter-miniform-inner-wrapper {}
.newsletter-miniform-label {
	margin-bottom: (@common-gutter / 2);
}
.newsletter-miniform-email-input-wrapper {
	margin-bottom: 1em;
	margin-bottom: 1rem;
}



.photo-link-to-high-resolution {
	display: block;
	position: relative;

	&:focus,
	&:hover {
		&::after {
			content: "Click for full resolution image for sharing »";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;

			min-height: 100%;
			padding: 5px;

			background: fade(#FFF, 75%);
			color: #333;
		}
	}
}


// Embed Wrapper additions & adjustments
// ---------------------------------------
.embed-wrapper-wrapper {
	width: 640px;
	max-width: 100%;
}
.embed-wrapper > iframe {
	margin: 0;
}
