@import "variables";

@font-face {
  font-family: 'perch-icons';
  src:  url('@{icomoon-font-path}/perch-icons.eot?9gv0y6');
  src:  url('@{icomoon-font-path}/perch-icons.eot?9gv0y6#iefix') format('embedded-opentype'),
    url('@{icomoon-font-path}/perch-icons.ttf?9gv0y6') format('truetype'),
    url('@{icomoon-font-path}/perch-icons.woff?9gv0y6') format('woff'),
    url('@{icomoon-font-path}/perch-icons.svg?9gv0y6#perch-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'perch-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user {
  &:before {
    content: @icon-user;
  }
}
.icon-instagram {
  &:before {
    content: @icon-instagram;
  }
}
.icon-checkmark {
  &:before {
    content: @icon-checkmark;
  }
}
.icon-filter {
  &:before {
    content: @icon-filter;
  }
}
.icon-google-plus {
  &:before {
    content: @icon-google-plus;
  }
}
.icon-google-plus-alt {
  &:before {
    content: @icon-google-plus-alt;
  }
}
.icon-pinterest {
  &:before {
    content: @icon-pinterest;
  }
}
.icon-pinterest-alt {
  &:before {
    content: @icon-pinterest-alt;
  }
}
.icon-youtube {
  &:before {
    content: @icon-youtube;
  }
}
.icon-mail {
  &:before {
    content: @icon-mail;
  }
}
.icon-twitter {
  &:before {
    content: @icon-twitter;
  }
}
.icon-twitter-alt {
  &:before {
    content: @icon-twitter-alt;
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook;
  }
}
.icon-share {
  &:before {
    content: @icon-share;
  }
}
.icon-table {
  &:before {
    content: @icon-table;
  }
}
.icon-file-pdf {
  &:before {
    content: @icon-file-pdf;
  }
}
.icon-paperclip {
  &:before {
    content: @icon-paperclip;
  }
}
.icon-warning-stop {
  &:before {
    content: @icon-warning-stop;
  }
}
.icon-enter {
  &:before {
    content: @icon-enter;
  }
}
.icon-exit {
  &:before {
    content: @icon-exit;
  }
}
.icon-play {
  &:before {
    content: @icon-play;
  }
}
.icon-pause {
  &:before {
    content: @icon-pause;
  }
}
.icon-info {
  &:before {
    content: @icon-info;
  }
}
.icon-question {
  &:before {
    content: @icon-question;
  }
}
.icon-warning {
  &:before {
    content: @icon-warning;
  }
}
.icon-alert {
  &:before {
    content: @icon-alert;
  }
}
.icon-blocked {
  &:before {
    content: @icon-blocked;
  }
}
.icon-heart {
  &:before {
    content: @icon-heart;
  }
}
.icon-heart-outline {
  &:before {
    content: @icon-heart-outline;
  }
}
.icon-link {
  &:before {
    content: @icon-link;
  }
}
.icon-menu {
  &:before {
    content: @icon-menu;
  }
}
.icon-lock {
  &:before {
    content: @icon-lock;
  }
}
.icon-unlocked {
  &:before {
    content: @icon-unlocked;
  }
}
.icon-settings {
  &:before {
    content: @icon-settings;
  }
}
.icon-search {
  &:before {
    content: @icon-search;
  }
}
.icon-zoom-in {
  &:before {
    content: @icon-zoom-in;
  }
}
.icon-zoom-out {
  &:before {
    content: @icon-zoom-out;
  }
}
.icon-print {
  &:before {
    content: @icon-print;
  }
}
.icon-feed {
  &:before {
    content: @icon-feed;
  }
}
.icon-feed-alt {
  &:before {
    content: @icon-feed-alt;
  }
}
.icon-wordpress {
  &:before {
    content: @icon-wordpress;
  }
}
.icon-wordpress-alt {
  &:before {
    content: @icon-wordpress-alt;
  }
}
.icon-download {
  &:before {
    content: @icon-download;
  }
}
.icon-credit {
  &:before {
    content: @icon-credit;
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar;
  }
}
.icon-expand {
  &:before {
    content: @icon-expand;
  }
}
.icon-contract {
  &:before {
    content: @icon-contract;
  }
}
.icon-chevron-down {
  &:before {
    content: @icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: @icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: @icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: @icon-chevron-up;
  }
}
.icon-home {
  &:before {
    content: @icon-home;
  }
}
.icon-vimeo {
  &:before {
    content: @icon-vimeo;
  }
}
.icon-vimeo-alt {
  &:before {
    content: @icon-vimeo-alt;
  }
}
.icon-linkedin {
  &:before {
    content: @icon-linkedin;
  }
}
.icon-linkedin-alt {
  &:before {
    content: @icon-linkedin-alt;
  }
}
.icon-rarebird-mark {
  &:before {
    content: @icon-rarebird-mark;
  }
}
.icon-facebook-alt {
  &:before {
    content: @icon-facebook-alt;
  }
}

