//
// Checkout pages
// --------------------------------------------------

.page-checkout {
	//
	.input-field,
	.button {
		vertical-align: middle;
	}


	.cart-section-heading {
		padding: 0.2em 0.5em 0.3em;

		background-color: lighten(@secondary-site-color, 45%);
		text-align: center;
		font-size: 1.3em;
		color: @secondary-site-color;
		text-shadow: 0 0 1em #FFF, 0 1px 0 #FFF;
	}
}
