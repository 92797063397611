//
// Layout - Header
// --------------------------------------------------


// Header
// ---------------------------------------
.global-header {
	position: fixed;
	top: -(@header-mobile-height);
	left: 0;
	right: 0;
	z-index: 5;

	padding-top: @utility-nav-mobile-height;
	height:      @header-mobile-height;
	line-height: @header-mobile-height;

	// background-color: fade(@header-bg-color, 92%); // if fading
	background-color: fade(@header-bg-color, 100%);
	.font-smoothing ();
	color: #fff;

	&.fast {
		.transition (none);
	}
	&.show {
		top: 0;
	}
	// &.show-not-top {
	// 	background-color: fade(@header-bg-color, 90%);
	// }

	  @the-transition: top .3s ease-out, background-color .4s ease-out;
	.transition (@the-transition);
}


// Navigation Wrapper
// ---------------------------------------
.global-outer-navigation-wrapper {
	margin: @header-mobile-height 0 0;
	overflow-y: scroll;

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;

	background-color: fade(@navigation-bg-color, 92%);

	// hiding
	display: none;

	// Animation that plays when it loads (or gets its display: none overriden)
	.animation(fadeIn .25s ease);

	// Unhide when this class is present
	&.is-active {
		display: block;
	}

	// Helper class from JS
	&.animating-out {
		.animation(fadeOut .25s ease 0s 1 normal forwards);
	}
}


// Base Nav styles
// ---------------------------------------
.global-nav {
	// #font-stack > .sans-serif();
	// text-transform: uppercase;
}

.global-nav-list {
	margin: 0;
	padding: 0;
	list-style: none;
}
.global-nav-list {
	.animation(fadeInUp .4s ease);
}

// General Menu styling
.global-nav-li {

	&:focus,
	&:hover {
		> .global-nav-link {
			background-color: fade(#000, 10%);
			color: #fff;
		}
	}
	// The active page gets a changed link color beneath
	&.is-active {
		> .global-nav-link {
			color: @light-link-color;

			&:before {
				content: "» ";
			}
		}
	}
}

.global-nav-link {
	display: block;
	padding: 0 1em;
	height: 3em;
	line-height: 3em;

	font-size: 1.1em;
	text-decoration: none !important;
	color: #fff;
}


// Second Tier navigation / submenu / dropdown
.global-nav-list-child {
	// mobile hiding
	display: none;

	.strip_list ();
	margin-top: 0;

	// States
	.is-open > & {
		display: block;
	}

	// Children
	.global-nav-link {
		padding-left: 2em;
	}
}


// Utility Nav
// ---------------------------------------
.global-utility-nav {
	background-color: @utility-nav-mobile-bg-color;
	// color: #000;
}

.global-utility-nav-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

// .global-utility-nav-li {
// 	//
// }

.global-utility-nav-link {
	display: block;
	padding: 0 0.75em;
	color: @utility-nav-mobile-link-color;

	&:focus,
	&:hover {
		color: @utility-nav-link-highlight;
	}
}


// Main Nav
// ---------------------------------------
// .global-main-nav {
// 	//
// }


// Logo
// ---------------------------------------
.global-logo {
	position: absolute;
	top: @mobile-logo-margin;
	left: @mobile-logo-side-margin;
	z-index: 11;
}

.global-logo-image {
	width: auto;
	height: @global-logo-mobile-height;
}


// Search
// ---------------------------------------

// Font sizes set to 16px to prevent Safari from auto-zooming in when the field is focused
.global-search-input {
	font-size: 16px;

	vertical-align: top;
	width: 14em;
	height: 2em;
	line-height: 2em;
}
.button-global-search {
	vertical-align: top;
	font-size: 16px;
	text-transform: none;
	font-weight: normal;
	font-family: 'rarebird-site-icons';
}
	// As the browser width grows, grow the font size (and size of the input/button)
	@media (min-width: @screen-md-min) {
		.global-search-input,
		.button-global-search {
			font-size: 20px;
			font-size: 4vw;
		}
	}
	// Cap the input/button sizes
	@media (min-width: @screen-lg-min) {
		.global-search-input,
		.button-global-search {
			font-size: 39px;
		}
	}
.button-close-search {
	font-size: 16px;
}
	@media (min-width: @screen-md-min) {
		.button-close-search {
			font-size: inherit;
		}
	}

// responsive / mobile search button
.global-search-reveal-button {
	.user-select (none);

	position: absolute;
	top: 0;
	right: 0;
	z-index: 101; // Layer on top of .full-page-menu when search is brought up so it's still available for click

	width: 2.5em;
	// width: (@search-reveal-button-size - .5rem);
	height: 3.2em;
	height: @search-reveal-button-size;
	padding: 0 .5em;
	border: 0;

	background-color: transparent;

	font-size: 1.2em;

	.transition(all .25s ease);

	// States
	&.is-active,
	&:focus,
	&:hover {
		background-color: fade(#000, 10%);
	}
}


// Responsive navigation open/close links
// --------------------------------------------------

//  Animated Hamburger Responsive navigation menu
.animated-responsive-menu-link {
	.user-select (none);

	display: block;

	position: absolute;
	right: 2.5em;
	top: 0;
	z-index: 101; // Layer on top of .full-page-menu when search is brought up so it's still available for click

	width: @hamburger-button-width;
	height: 3.2em;
	height: @search-reveal-button-size;
	line-height: 3;
	line-height: (.9 * @search-reveal-button-size);
	padding-left: 10px;
	padding-right: 10px;
	border: 0;

	background: transparent;
	text-decoration: none !important;

	// Icon children -------------
	.bar {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin: auto;

		width: @hamburger-bar-width;
		height: @hamburger-bar-height;

		opacity: 1;
		background: @hamburger-bar-color;

		.transform (rotate(0deg));
		.transition (all .190s ease-in-out);
	}
	// positional adjustments
	.bar-1 {
		margin-top: -(@hamburger-bar-gutter);
	}
	// .bar-2,
	// .bar-3 {
	// 	// these *two* stay in the middle for default state
	// }
	.bar-4 {
		margin-top: @hamburger-bar-gutter;
	}

	// States -------------
	&:focus,
	&:hover {
		background: fade(#000, 10%);
	}
	&:hover {
		// .bar {
		// 	//
		// }
		.bar-1 {
			.transform (translateY(-2px));
		}
		.bar-4 {
			.transform (translateY(2px));
		}
	}

	&.is-active {
		//
		.bar-1,
		.bar-4 {
			width: 0%;
			margin-top: 0;
		}
		.bar-2 {
			.transform (rotate(45deg));
		}
		.bar-3 {
			.transform (rotate(-45deg));
		}
	}

}

// Close menu link at the bottom of the overlay menu
.responsive-menu-closer {
	display: none;

	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 11;

	height:      3em;
	height:      3rem;
	line-height: 3.3em;
	width: 100%;
	// background-color: fade(@primary-site-color, 75%);
	background: -moz-linear-gradient(top,    fade(#000, 0%) 0%, fade(#000, 50%) 75%);
	background: -webkit-linear-gradient(top, fade(#000, 0%) 0%, fade(#000, 50%) 75%);
	background: -ms-linear-gradient(top,     fade(#000, 0%) 0%, fade(#000, 50%) 75%);
	background: linear-gradient(to bottom,   fade(#000, 0%) 0%, fade(#000, 50%) 75%);

	border: 0;

	font-family: @font-family-sans-serif;
	color: #fff;

	&:focus,
	&:hover {
		color: @link-color;
		background-color: @primary-site-color;
	}

	&.visible {
		display: block;
	}
}
