
// // Trick for keeping list items from wrapping around floated material, reinforcing a visually _continuous_ list rather than the items wrapping
// menu,
// ol,
// ul {
//   display: inline-block;
// }


.wp-content,
.post {
	img {
		margin: 0 0 1.5em 0;
		max-width: 100%;
		height: auto;
	}
}

iframe {
	display: block;
	margin: 1em 0;
}

.wp-caption,
.wp-image-embed {
	margin-bottom: 1.5em;
	text-align: center;
	padding-top: 5px;

	// Hide single & double line breaks following wp-captions,
	// allowing for "grid" style layouts with multiple captions after another
	+ br,
	+ br + br {
		display: none;
	}
}
.wp-caption img,
.wp-image-embed img {
	border: 0 none;
	padding: 0;
	margin: 0;
}
.wp-caption p.wp-caption-text {
	line-height: 1.5;
	font-size: 10px;
	margin: 0;
}

.alignleft,
img.alignleft {
	display: block;
}
.alignright,
img.alignright {
	display: block;
}
@media (min-width: @screen-md-min) {
	.alignleft,
	img.alignleft {
		display: inline-block;
		float: left;
		max-width: 33%;
		padding-right: 2em;
	}
	.alignright,
	img.alignright {
		display: inline-block;
		float: right;
		max-width: 33%;
		padding-left: 2em;
	}

	.alignleft {
		.alignleft {
			float: none;
			max-width: 100%;
			padding: 0;
			margin: auto;
		}
	}
	.alignright {
		.alignright {
			float: none;
			max-width: 100%;
			padding: 0;
			margin: auto;
		}
	}
	// .align

	.grid-figure,
	.grid-figure ~ .alignleft {
		float: none;
		vertical-align: top;
		padding: 0 1em;
		margin-right: -0.25em;
	}
}

.aligncenter,
img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both;
}

// Creates a "hero" or block of an image.
.alignnone,
img.alignnone {
	display: block;

	text-align: left;
}
// For align: none images, cap their height per WMU's request
.alignnone img {
	width: auto;
	max-height: 333px;

	// If viewport is tall, allow for taller images
	@media (min-height: 900px) {
		max-height: 400px;
		max-height: 38vh;
	}
}



.wp-smiley {
	margin: 0 !important;
	max-height: 1em;
}
blockquote.left {
	margin-right: 20px;
	text-align: right;
	margin-left: 0;
	width: 33%;
	float: left;
}
blockquote.right {
	margin-left: 20px;
	text-align: left;
	margin-right: 0;
	width: 33%;
	float: right;
}
// .gallery dl {}
// .gallery dt {}
// .gallery dd {}
// .gallery dl a {}
// .gallery dl img {}
// .gallery-caption {}

// .size-full {}
// .size-large {}
// .size-medium {}
// .size-thumbnail {}
