//
// Product Listing specific styles
// --------------------------------------------------

.page-product-listing {
	//
	.main-content-container {
		padding-top: (@common-gutter * 2);
	}
}

.product-listing-page-heading {
	padding-bottom: (@common-gutter / 2);
	border-bottom: 1px solid #DDD;

	// font-size: 2.5rem;
	// letter-spacing: -0.03em;
	// color: @wc-green;
}
