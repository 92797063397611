//
// Layout - Main (<main> element & main containers)
// --------------------------------------------------


// Main Content Inner
// ---------------------------------------
.global-main {
	position: relative;
	// z-index: 1;

	padding-bottom: 0.1px; // Prevents last item's margin from collapsing, allowing this element's background-color to be honored. Another (more drastic) solution is to set `overflow: hidden;`.
	margin-top: @header-mobile-height; // compensate for the fixed navigation layered on top of it

	background-color: @main-content-bg-color;
}

.main-content-container {
	padding-top:    @content-vertical-spacing;
	padding-bottom: @content-vertical-spacing;

	@media (min-width: @site-max-width) {
		padding-top:    @content-vertical-spacing-locked;
		padding-bottom: @content-vertical-spacing-locked;
	}
}
