//
// Alert Blocks
// --------------------------------------------------
// * 	.alert for a block-style alert
// * 	add extensions for different styles of alerts
// --------------------------------------------------
// * 	Plugin styles by @k_bav inspired by BS Alerts
// --------------------------------------------------

.alert {
	.island;
	position: relative;
	border: 1px solid lighten(@alert-color, 30%);
	background-color: lighten(@alert-color, 50%);
	color: @alert-color;
	text-shadow: 0 1px 0 rgba(255,255,255,.65);

	.box-shadow (0 0 2px 1px fade(#000, 17%));

	// Element Children
	// --------------------------
	> .heading {
		font-weight: bold;
		color: inherit;
	}
	> .close {
		position: absolute;
		top:   0;
		right: 0;

		width: 		 1.4em;
		line-height: 1em;
		height: 	 1.4em;
		border: 0;

		background-color: transparent;
		text-align: center;
		font-size: 1.4em;
		text-decoration: none;
		color: #777;
		color: fade(#000, 50%);

		&:focus,
		&:hover {
			background-color: #777;
			background-color: fade(#000, 50%);
			color: #fff;
		}
	}
}

// Layout extensions
// ---------------------------------------
.alert--borderless {
	border: 0;
	.box-shadow (none);
}

// Functional / color extensions
// ---------------------------------------
.alert-warning {
	border-color: @warning-color;
	background-color: lighten(@warning-color, 40%);
	color: darken(@warning-color, 10%);
	> .close:focus,
	> .close:hover { background-color: @warning-color; }
}
.alert-error {
	border-color: @error-color;
	background-color: lighten(@error-color, 40%);
	color: @error-color;
	> .close:focus,
	> .close:hover { background-color: @error-color; }
}
.alert-success {
	border-color: @success-color;
	background-color: lighten(@success-color, 44%);
	color: @success-color;
	> .close:focus,
	> .close:hover { background-color: @success-color; }
}
.alert-info {
	border-color: @info-color;
	background-color: lighten(@info-color, 40%);
	color: @info-color;
	> .close:focus,
	> .close:hover { background-color: @info-color; }
}
