//
// Browser Fixes
// --------------------------------------------------


// IE 10 Viewport Fixes
// ---------------------------------------

// Fix for IE 10 Desktop not respecting viewport meta tag
// cf. https://github.com/h5bp/html5-boilerplate/issues/1047
@-ms-viewport {
	width: device-width;
}

// Above fix breaks IE Mobile v10. Fix-fix:
// cf. https://github.com/h5bp/html5-boilerplate/pull/1243
@media screen and (max-width: 400px) {
	@-ms-viewport {
		width: 320px;
	}
}



// Outdated Browser message
// ---------------------------------------
.browser-outdated-message {
	max-width: @site-max-width;
	padding: 3.5% 1em;
	margin: auto;

	background-color: #eecfcf;

	text-align: center;
	line-height: 1.5;
	color: #B94A48;
}
