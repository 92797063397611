//
// WordPress Resets & Overrides
// --------------------------------------------------



// WP Admin Bar
// ---------------------------------------

// Move the global header down the height of the WP admin bar
body.admin-bar {

	.global-header.show {
		margin-top: @wp-admin-bar-height;
	}

	@media screen and (max-width: 782px) {
		.global-header.show {
			margin-top: @wp-admin-bar-mobile-height;
		}
	}
}

// Override: ensure the WP Admin Bar stays position: fixed (as opposed to their style for making it absolute at 600px and below, as of this writing)
#wpadminbar {
	position: fixed !important;
}



// Blog Header Image
// ---------------------------------------
.blog-header-image-wrapper {
	margin-bottom: 1em;
	margin-bottom: 1rem;

	@media (min-width: @screen-md-min) {
		margin-bottom: 2em;
		margin-bottom: 2rem;
	}
}
.blog-header-image {
	display: block;
	max-width: 100%;
	height: auto;
}



// Post & Post Meta
// ---------------------------------------

// .entry-intro {
// 	//
// }

.post {
	.clear_float();
	// .clear_fix();
}

.post,
.search .page,
.widget-area {
	position: relative;
	// padding: @common-gutter;
	margin-bottom: @common-gutter;
	margin-bottom: @common-vertical-gutter;

	background-color: #fff;
}
	.post + .post {
		padding-top: (@common-gutter * 2);
		padding-top: (@common-vertical-gutter * 2);
		margin-top: (@common-gutter * 2);
		margin-top: (@common-vertical-gutter * 2);
		border-top: 1px solid @line-divider-lighter;
	}

.post {
	.entry-title {
		margin-bottom: (@common-gutter / 2);

		font-size: 2.5em;

		> a {
			color: #707A7C;
		}
	}
}
.non-single-entry-title {
	margin-bottom: (@common-gutter / 4);
}


.entry-date,
.entry-meta {
	font-style: italic;
	color: lighten(@global-text-color, 25%);
}
// .entry-date {
// 	//
// }
.entry-meta {
	.clear_float ();
	// padding: 5px 0;
	// margin-top: -10px;
	margin-bottom: @common-gutter;
	// border-top: 1px solid @line-divider-color;
	// border-bottom: 1px solid @line-divider-color;

	// font-size: 0.75em;

	.cat-links {
		float: left;
	}
	.comments-link {
		float: right;
	}
	.icon-account {
		color: lighten(@global-text-color, 35%);
	}
}

.entry-content {
	.clear_float ();
	font-size: 1.0em;
	line-height: 1.5;
}

.entry-footer {
	padding-top: 0.5em;
	border-top: 1px solid @line-divider-color;
	margin-top: 1em;

	font-size: 0.75em;
	color: lighten(@global-text-color, 15%);
}


.post-date {
	position: absolute;
	top: @common-gutter;
	right: 0;

	line-height: 1;
	font-size: 1em;
	// color: @fc-gray;

	// Element children
	.day {
		position: relative;
		top: -4px;
		font-size: 3.3em;
		font-weight: bold;
	}
	.month {
		font-size: 1.45em;
		text-transform: uppercase;
	}
	.year {
		font-size: 1.3em;
	}
}


#nav-above,
#nav-below {
	.clear_float ();
	padding: 5px 0;

	font-size: 1em;

	// a {
	// 	color: @fc-gray;

	// 	&:focus,
	// 	&:hover {
	// 		color: @fc-orange;
	// 	}
	// }

	.arrow {
		display: inline-block;
		vertical-align: middle;
		font-size: 1.6em;
		font-weight: bold;
	}
}
#nav-below {
	margin-top:   -10px;
	margin-bottom: 10px;
}

.nav-previous {
	float: left;
}

.nav-next {
	float: right;
}



// Sidebar
// ---------------------------------------
#sidebar .global-search:focus {
	width: 185px;
}

.widget-area {
	// Maximize spacing on the right so the search doesn't collapse the button as much
	margin-right: -(@common-gutter);
}

.sid {
	padding-left: 0;
	list-style: none;

	ul {
		margin-top: 10px;
		padding-left: 0;
	}
}

.widget-container {
	margin-bottom: (@common-gutter * 2);
	list-style: none;

	li {
		margin: 4px 0;
		list-style: none;

		font-size: 0.9em;
	}
	a {
		// font-style: italic;
		color: @alt-link-color;

		&:focus,
		&:hover {
			color: @alt-link-highlight;
		}
	}
}

.widget-title {
	margin-bottom: 0.5em;

	// font-family: @font-family-clean;
	font-size: 1.2em;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.175em;
}



// Comments
// ---------------------------------------
// #comments-opener-section {
// 	padding-top: @common-gutter;
// 	border-top: 1px solid @line-divider-color;
// 	margin-top: @common-gutter;
// }

#comments {
	// // hiding method:
	// max-height: 0;
	// overflow: hidden;
	// opacity: 0;
	// .transition(opacity .4s ease-out);
	// // end hiding method

	// reveal method
	// &.is-open {
	// 	max-height: none;
	// 	overflow: visible;
	// 	opacity: 1;
	// }

	padding-top: @common-gutter;
	border-top: 1px solid @line-divider-color;
	margin-top: @common-gutter;
	margin-bottom: @common-gutter;

	// background-color: #fff;
	font-size: 0.9em;
	// .box-shadow (0 0 6px 0 fade(#000, 8%));

	textarea {
		width: 100%;
	}
}

#comments-list {
	font-size: 0.95em;

	ul {
		padding-left: 0;

		> li {
			list-style: none;
		}
	}

	.comment {
		padding: (@common-gutter / 2);
		margin-bottom: @common-gutter;

		background-color: #f7f7f7;

		.children {
			// padding-left: (@common-gutter / 2);
			padding-left: 0;
			list-style: none;

			.comment {
				border: 1px solid #ccc;
				background-color: #fff;
			}
			// .children .children {
			// 	padding-left: 0;
			// }
		}
		.avatar {
			max-width: 38px;
			height: auto;
		}
		.comment-meta {
			padding-bottom: 4px;
			border-bottom: 1px solid fade(#000, 7%);
			margin-bottom: 4px;

			font-size: 0.8em;
		}
	}
}

// Write a comment section
// --------------------------
#respond {
	//
	p {
		margin-top: 0;
	}
	textarea,
	input[type="text"],
	input[type="email"],
	input[type="url"] {
		.input-field;
		margin-bottom: 0;
	}

	// Hides the default WP submit button; we are using a custom made button, editable in .../{theme}/custom/comments.php (imported in functions.php)
	// #submit {
	// 	display: none;
	// }
}



// DEPRECATING: This is a mish-mash of styles we either don't use to my knowledge, or are simply commented out and not in the proper place. Keeping around for a little while for reference just in case. --> 2016-07-19 9:49 AM KB
// // Inline Syles
// // ---------------------------------------
// .clear,
// .figure-row {
// 	.clear_float ();
// 	clear:both;
// }
// // .sticky {

// // }
// // .bypostauthor {

// // }
// // .wp-caption {

// // }
// // .wp-caption-text {

// // }
// // .gallery-caption {

// // }
// .size-full {
// 	max-width: 100%;
// 	height: auto;
// }



// // Caption & Alignment
// // ---------------------------------------
// .wp-caption {
// 	max-width: 100%;
// 	padding: 5px;
// 	background-color: #eaeaea;

// 	> img {
// 		max-width: 100%;
// 		height: auto;
// 	}
// }
// .wp-caption-text {
// 	padding: 0;
// 	font-size: 0.8em;
// 	font-style: italic;
// 	color: #777;
// }

// .wp-caption .wp-caption-text,
// .entry-caption,
// .gallery-caption {
// 	color: #888;
// 	font-size: .85em;
// 	font-style: italic;
// 	font-weight: @font-weight-base;
// }

// // Non-floated alignment
// // --------------------------
// .alignnone {
// 	display: block;
// }
// img.alignnone {
// 	margin: 5px 0;
// }

// .aligncenter {
// 	display: block;
// 	margin-left: auto;
// 	margin-right: auto;
// }
// img.aligncenter {
// 	margin: 5px auto;
// }

// // Floated alignment: only apply at wide enough widths
// // --------------------------
// @media (min-width: @wp-caption-alignment-min-width) {
// 	.alignleft,
// 	.alignright {
// 		max-width: 50%;
// 	}
// 	.alignleft {
// 		float: left;
// 	}
// 	.alignright {
// 		float: right;
// 	}
// 	img.alignleft {
// 		margin: 5px 20px 5px 0;
// 	}
// 	.wp-caption.alignleft {
// 		margin: 5px 10px 5px 0;
// 	}
// 	img.alignright {
// 		margin: 5px 0 5px 20px;
// 	}
// 	.wp-caption.alignright {
// 		margin: 5px 0 5px 10px;
// 	}
// 	.wp-caption.alignleft + ul,
// 	.wp-caption.alignleft + ol  {
// 		list-style-position: inside;
// 	}
// }



// Deprecating -- not sure this is really ever used... or would need to be used. --> 2016-07-19 9:40 AM KB
// img.wp-smiley,
// .rsswidget img {
// 	border: 0;
// 	border-radius: 0;
// 	box-shadow: none;
// 	margin-bottom: 0;
// 	margin-top: 0;
// 	padding: 0;
// }



// Media Elements
// ---------------------------------------
.hentry .mejs-mediaelement,
.hentry .mejs-container .mejs-controls {
	background: #220e10;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-loaded,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
	background: #fff;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-current {
	background: #ea9629;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-total,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
	background: #595959;
}

.hentry .mejs-controls .mejs-time-rail span,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
	border-radius: 0;
}



// Utilities
// ---------------------------------------
.screen-reader-text {
	.sr-only;
}
