//
// Layout - Footer
// --------------------------------------------------

.global-footer {
	position: relative;
	z-index: 2;

	padding-top: @content-vertical-spacing;
	padding-bottom: @content-vertical-spacing;

	background-color: @footer-bg-color;
	color: #fff;

	@media (min-width: @site-max-width) {
		padding-top: @content-vertical-spacing-locked;
		padding-bottom: @content-vertical-spacing-locked;
	}
}

.global-footer-copyright {
	padding-top: .75em;
	text-transform: uppercase;
	font-family: @font-family-sans-serif;
	font-size: 0.6em;
	color: #cecfd4;
}
