//
// Components
// --------------------------------------------------


// Announcements
// ---------------------------------------

// Variables
// --------------------------
@announcement-padding: 				(@common-gutter / 2) 0;
@announcement-margin: 				0 auto;
@announcement-fallback-bg-color: 	#F7F1E4;
@announcement-bg: 					#F7F1E4; // Use this variable for a gradient if desired
@announcement-text-align: 			center;
@announcement-text-color: 			@global-text-color;
// --------------------------

.announcement {
	padding: @announcement-padding;
	margin: @announcement-margin;

	background: @announcement-fallback-bg-color;
	background: @announcement-bg;
	color: @announcement-text-color;
	text-align: @announcement-text-align;
}



// Islands
// ---------------------------------------
.island {
	.clear_float ();
	padding: @island-padding;
	margin-bottom: @island-margin-bottom;

	background-color: @island-bg-color;

	// Prevent last item from visually increasing the gutter
	> :last-child {
		margin-bottom: 0;
	}

	// Conditional parentage
	.modal & {
		margin-left:  -(@common-gutter);
		margin-right: -(@common-gutter);
	}
}

.island--highlighted {
	background-color: @island-highlighted-bg-color;
}



// Breadcrumbs
// ---------------------------------------

// Variables
// --------------------------
@breadcrumbs-wrapper-margin: 			auto;

@breadcrumbs-font-size: 				1em;
@breadcrumbs-margin-bottom: 			(@common-gutter / 2);

@breadcrumbs-link-color: 				inherit;

@breadcrumbs-divider-character: 		"\00BB";
@breadcrumbs-divider-font-size: 		1.25em;
@breadcrumbs-divider-font-weight: 		normal;
@breadcrumbs-divider-color: 			#AAA;
// --------------------------


// Elements
// --------------------------

// Outermost <nav> wrapper
.breadcrumbs-wrapper {
	margin: @breadcrumbs-wrapper-margin;
}

// Wrapping <ol>
.breadcrumbs {
	list-style: none;

	padding: 0;
	margin: 0 0 @breadcrumbs-margin-bottom;

	font-size: @breadcrumbs-font-size;
}

// Each <li> wrapping item
.breadcrumb-item {
	display: inline-block;

	// Divider
	&:after {
		display: inline-block;
		content: @breadcrumbs-divider-character;
		padding: 0 0.1em 0 0.25em;

		font-size: @breadcrumbs-divider-font-size;
		font-weight: @breadcrumbs-divider-font-weight;
		color: @breadcrumbs-divider-color;
	}
		// No divider after last element
		&:last-child:after {
			display: none;
			content: "";
		}
}

// The <a> breadcrumb itself
.breadcrumb {
	color: @breadcrumbs-link-color;
}



// Pagination
// ---------------------------------------

// Variables
// --------------------------
   @pagination-padding: 				0.5em 0.75em;
   @pagination-border-width: 			1px;
   @pagination-border-color: 			@line-divider-color;
   @pagination-bg-color: 				transparent;
   @pagination-font-size: 				0.875em;
   @pagination-text-color: 				@link-color;

   @pagination-item-spacing: 			0.5em;

   @pagination-hover-border-color: 		@link-color;
   @pagination-hover-bg-color: 			@link-color;
   @pagination-hover-text-color: 		#FFF;

   @pagination-active-border-color: 	darken(@pagination-hover-border-color, 15%);
   @pagination-active-bg-color: 		darken(@pagination-hover-bg-color, 15%);
   @pagination-active-text-color: 		#FFF;

   @pagination-current-border-color: 	#999;
   @pagination-current-bg-color: 		#999;
   @pagination-current-text-color: 		#FFF;
// --------------------------

// .pagination-wrapper {
// 	//
// }
.pagination-list {
	.clear_float();

	list-style: none;
	padding: 0;
	margin: 0;
}
.pagination-item {
	// Listing method
	float: left;
	margin-right: @pagination-item-spacing;

	// Resume styling
	margin-bottom: 0.5em; // In case of these wrapping on mobile, create separtion at the item level
}

.pagination-link,
.pagination-non-link {
	display: block;
	padding: @pagination-padding;
	border: @pagination-border-width solid @pagination-border-color;

	background: @pagination-bg-color;
	font-size: @pagination-font-size;
	line-height: 1;

	.transition(all 0.15s ease);
}
.pagination-link {
	color: @pagination-text-color;
	text-decoration: none !important;

	// States
	// --------------------------
	&:focus,
	&:hover {
		border-color: @pagination-hover-border-color;

		background-color: @pagination-hover-bg-color;
		color: @pagination-hover-text-color;
	}
	&:active {
		border-color: @pagination-active-border-color;

		background-color: @pagination-active-bg-color;
		color: @pagination-active-text-color;
	}

	.current > & {
		border-color: @pagination-current-border-color;

		background-color: @pagination-current-bg-color;
		color: @pagination-current-text-color;

		cursor: default;
	}
	.disabled > & {
		opacity: 0.5;

		border-color: @pagination-border-color;
		background-color: transparent !important;
		color: @global-text-color;

		cursor: default;
		cursor: not-allowed;

	}
}
.pagination-non-link {
	color: @global-text-color;
}

// Extensions
// --------------------------
@media (min-width: @screen-sm-min) {
	.pagination--grouped {
		display: inline-block;
		width: auto;
		border: @pagination-border-width solid @pagination-border-color;

		.pagination-item {
			margin-left: -@pagination-border-width;
			margin-right: 0;
			margin-bottom: 0;

			padding-right: @pagination-border-width;

			&:last-child { padding-right: 0; }
		}
		.pagination-link,
		.pagination-non-link {
			border-width: 0 0 0 @pagination-border-width;
		}
		.pagination-link {
			&:focus,
			&:hover {
				border-color: @pagination-border-color;
			}
		}
		.current > .pagination-link {
			border-color: @pagination-border-color;
		}
	}
}



// Full page menu
// ---------------------------------------
.full-page-menu {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	min-height: 2000px;
	min-height: 100vh;
	z-index: 100;

	padding-top: 200px;
	padding-top: -webkit-calc(~"@{header-mobile-height} + 2em");
	padding-top: -moz-calc(~"@{header-mobile-height} + 2em");
	padding-top: calc(~"@{header-mobile-height} + 2em");

	text-align: center;
	background-color: fade(@navigation-bg-color, 80%);

	// Have cursor turn to an "X" to indicate ability to click to close
	// Reset said cursor for inner elements
	cursor: auto;
	cursor: pointer;
	cursor: url("@{site-path}@{img-dir}/cursor-close-shadowed.png"), pointer;
	cursor: url("@{site-path}@{img-dir}/cursor-close-shadowed.png") 15 15, pointer;

	.animation(fadeIn .25s ease);

	&.is-active {
		display: block;
	}

	&.animating-out {
		.animation(fadeOut .25s ease 0s 1 normal forwards);
	}
}
.full-page-menu-inner {
	.animation(fadeInUp .4s ease);

	// Reset the above rule for when mouse inside this element
	cursor: default;
}



// Grid List
// ---------------------------------------
.grid-list {
	.strip_list();
	.clear_float();
}
.grid-list__item {
	float: none; // cancel the float
	display: inline-block; // make 'em inline
	margin-right: -.25em; // overcome the inline-block implied spacing to the right
	vertical-align: top; // move all items to top for alignment

	margin-bottom: 1em;

	// &:last-child { margin-right: 0; } // Not sure if necessary
}
// .grid-list__link {
// 	// For wrapping anchor tags
// }



// List Extensions
// ---------------------------------------
.list-bare {
	.strip_list ();
}
.list-ui {
	.strip_list ();
	.clear_float ();

	margin: 0;

	> li { float: left; }
}



// Figure feature / floated images
// ---------------------------------------
.figure-feature {
	display: block;
	margin: 0 0 1em;

	img {
		display: block;
		max-width: 100%;
		height: auto !important;
		margin: auto;
	}
	> figcaption {
		display: block;
		padding: 5px @common-gutter 0;

		font-size: .85em;
		font-style: italic;
		color: lighten(#000, 50%);
	}

	@media (min-width: @screen-sm-min) {
		> figcaption {
			font-size: .9em;
		}
	}
}

// Extensions
.figure-feature--left,
.figure-feature--right {
	padding: 0;
}

.figure-feature--right {
	//
	@media (min-width: @screen-sm-min) {
		float: right !important;
		padding: 0 @common-gutter;
		margin-right: -@common-gutter;
	}
}
.figure-feature--left {
	//
	@media (min-width: @screen-sm-min) {
		padding: 0 @common-gutter;
		margin-left: -@common-gutter;
	}
}
.figure-feature--centered {
	width: 100% !important;

	padding: 0 12%;

	@media (min-width: @screen-sm-min) {
		padding: 0 15%;
	}
}



// Blockquotes / Pullquotes
// ---------------------------------------
.quotation-wrapper {
	padding: (@common-gutter - 10px);
	margin: 0 0 1em;

	background-color: @gray-lighter;
	font-family: @font-family-sans;
	font-size: 1.2em;
	// color: @global-text-color;
}
blockquote {
	margin: 0 0 .5em;
}
.quotation-citation {
	padding: 0 (@common-gutter - 10px);

	color: inherit;

	&:before {
		content: "— ";
		display: inline-block;
		width: 2em;
		// margin-right: -.25em;
	}
}
.citation-name {
	display: inline-block;
}
.citation-more-info {
	margin-left: 2em;
}

// Medium sizes and up
@media (min-width: @screen-md-min) {

	.quotation-wrapper {
		padding: @common-gutter;
	}

	blockquote {
		font-size: 1.1em;
	}

	.quotation-citation {
		padding: 0 @common-gutter;

		font-size: 0.9em;
	}
}

// Conditional parentage changes
.figure-feature--left,
.figure-feature--right {
	blockquote,
	.quotation-citation {
		font-size: .85em;
	}
}



// Responsive Video embed wrapper
// ---------------------------------------
.embed-wrapper {
	position: relative;
	max-width: 100%;
	height: 0;
	// Standard video size (16:9)
	padding-top: 56.25%;
	margin-bottom: 1em;
	overflow: hidden;

	// Variations:
	// Television ratio (4:3)
	&.embed--4x3 {
		padding-top: 74.3%; // would be 75%, but this seems to do a better job of cropping the rounding errors
	}
	// Anamorphic widescreen ratio (2.39:1)
	&.embed--anamorphic_widescreen {
		padding-top: 41.735%; // would be 41.841%, but pre-emptively manually flooring to maintain crop of rounding errors
	}

	// Position the iframe
	> iframe {
		position: absolute;
		top: 0;
		left: 0;
		// right: 0;
		// bottom: 0;

		width: 100%;
		height: 100%;
	}
}



// Responsive Tables
// --------------------------------------------------
.responsive-table-wrapper {
	max-width: 100%;
	min-height: 0.01%;
	margin-bottom: 1em;
	overflow: auto;

	// dynamic shadow effect
	overflow-x: auto;
	background-image:
		-webkit-linear-gradient(left,  #ffffff, rgba(255, 255, 255, 0)),
		-webkit-linear-gradient(right, #ffffff, rgba(255, 255, 255, 0)),
		-webkit-linear-gradient(left,  #c3c3c5, rgba(195, 195, 197, 0)),
		-webkit-linear-gradient(right, #c3c3c5, rgba(195, 195, 197, 0));
	background-image:
		linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0)),
		linear-gradient(to left,  #ffffff, rgba(255, 255, 255, 0)),
		linear-gradient(to right, #c3c3c5, rgba(195, 195, 197, 0)),
		linear-gradient(to left,  #c3c3c5, rgba(195, 195, 197, 0));
	background-position:
		0 0,
		100% 0,
		0 0,
		100% 0;
	background-repeat: no-repeat;
	background-color: white;
	background-size:
		4em 100%,
		4em 100%,
		1em 100%,
		1em 100%;
	background-attachment:
		local,
		local,
		scroll,
		scroll;

	> table {
		width: 100%;
		// min-width: 607px; // the default .page-container/.page-content width.
		margin-bottom: 0;
	}
	// th {
	// 	white-space: nowrap;
	// }
}



// // Link Enhancers
// // --------------------------------------------------
// .truncated-indicator {
// 	color: lighten(@global-text-color, 10%);
// }

// .link-indicator {
// 	position: relative;
// 	display: inline-block;
// 	vertical-align: top;
// 	font-size: 1.35em;
// 	line-height: 1;

// 	.transition(all .12s ease);


// 	a:hover & {
// 		.transform(translateX(.125em));
// 	}
// }

// // The whole block is a link. Defines hover effects
// .block-link {
// 	display: block;
// 	  @the-shadow: 0 0 0 3px fade(#fff, 0%), 0 0 0 4px fade(@link-color, 0%);
// 	.box-shadow (@the-shadow);

// 	// States
// 	&:focus,
// 	&:hover {
// 		background-color: fade(#fff, 50%);
// 		  @the-shadow: 0 0 0 3px fade(#fff, 95%), 0 0 0 4px fade(@link-color, 35%);
// 		.box-shadow (@the-shadow);
// 	}
// }



// Social icon listing
// --------------------------------------------------
.social-icons {
	padding: 0;
	margin: 0;
	list-style: none;

	> li {
		display: inline-block;
		float: none;
		margin-right: (0.5em - 0.25em);
		margin-bottom: (0.5em - 0.25em);
		vertical-align: top;
	}
}
.social-icon-link {
	display: block;

	&:focus,
	&:hover {
		background-color: #fff;
		text-decoration: none !important;

		&:before { opacity: .8; }
	}

	font-size: 32px; // perch-icons icon font's crisp size is on a 16px grid

	// Assign icon colors
	&.facebook 		{ color: @facebook-color !important; }
	&.twitter 		{ color: @twitter-color !important; }
	&.rss 			{ color: @rss-color !important; }
	&.youtube 		{ color: @youtube-color !important; }
	&.google-plus 	{ color: @google-plus-color !important; }
	&.pinterest 	{ color: @pinterest-color !important; }
	&.instagram 	{ color: @instagram-color !important; }
	&.mail 			{ color: @mail-color !important; }
}



// Badges
// ---------------------------------------

// Variables
// --------------------------
// Layout _________
@badge-display: 			inline-block;
@badge-padding: 			0.3em 0.5em 0.4em;
@badge-border-radius: 		@common-border-radius;
@badge-vertical-align: 		baseline;

// Type _________
@badge-font-size: 			0.75em;
@badge-font-weight: 		normal;
@badge-text-transform: 		uppercase;
@badge-line-height: 		1;
@badge-letter-spacing: 		0.05em;

// Colors _________
@badge-color: 				#FFF;
@badge-default-bg: 			#AAAAAA;
@badge-success-bg: 			@success-color;
@badge-info-bg: 			@info-color;
@badge-warning-bg: 			@warning-color;
@badge-danger-bg: 			@error-color;
// --------------------------


// Base class
// --------------------------
.badge {
	display: @badge-display;
	padding: @badge-padding;
	border-radius: @badge-border-radius;

	background-color: @badge-default-bg;
	vertical-align: @badge-vertical-align;
	text-align: center;
	font-size: @badge-font-size;
	font-weight: @badge-font-weight;
	text-transform: @badge-text-transform;
	line-height: @badge-line-height;
	letter-spacing: @badge-letter-spacing;
	color: @badge-color;
	white-space: nowrap;
}

// Color extensions
// --------------------------
.badge-success {
	background-color: @badge-success-bg;
}
.badge-info {
	background-color: @badge-info-bg;
}
.badge-warning {
	background-color: @badge-warning-bg;
}
.badge-danger {
	background-color: @badge-danger-bg;
}



// Jump link target coloring
// --------------------------------------------------
.jump-link-target {
	//
	&:target {
		.animation(jump-link-target 3.0s ease-out);
	}
}



// Footnotes
// --------------------------------------------------
.footnotes-block {
	padding-top: 1em;
	border-top: 1px solid @line-divider-color;
	margin-top: 1em;

	font-size: .85714em;

	p {
		margin-bottom: 1em;
	}
}
.footnote {
	.jump-link-target;

	padding: 0 (@common-gutter / 2);
}



// Not deprecated but pulling out of core until these are revised for use with radio inputs & re-tested -- KB 2016-03-07
// When refactoring, consider the use of unicode-bidi: https://css-tricks.com/almanac/properties/u/unicode-bidi/

// // Star Ratings / Product Ratings
// // --------------------------------------------------
// // with help via https://coderwall.com/p/iml9ka
// // 	+ http://lea.verou.me/2011/08/accessible-star-rating-widget-with-pure-css/
// // --------------------------------------------------

// // @vars -----
//    @star-size: 1.7em;
//    @star-default-color: #BBBBBB;
//    @star-hover-color: #FDE16D;
//    @star-rated-color: #FCC749;
// // @end vars

// .product-rating {
// 	.clear_float ();
// }
// .product-rating-inner-wrapper {
// 	float: left;
// }

// .product-rated-1   .star-1, // if rated 1, color in the first star
// .product-rated-1-5 .star-1, // if 1.5 rated, also color in the "1" star
// .product-rated-2   .star-2,
// .product-rated-2-5 .star-2,
// .product-rated-2   .star-2 ~ .star-icon, // If rated 2, color in the stars following star 2
// .product-rated-2-5 .star-2 ~ .star-icon,
// .product-rated-3   .star-3,
// .product-rated-3-5 .star-3,
// .product-rated-3   .star-3 ~ .star-icon,
// .product-rated-3-5 .star-3 ~ .star-icon,
// .product-rated-4   .star-4,
// .product-rated-4-5 .star-4,
// .product-rated-4   .star-4 ~ .star-icon,
// .product-rated-4-5 .star-4 ~ .star-icon,
// .product-rated-5   .star-5,
// .product-rated-5   .star-5 ~ .star-icon {
// 	color: @star-rated-color;
// }
// // The half-star ratings:
// .product-rated-1-5 .star-2:before,
// .product-rated-2-5 .star-3:before,
// .product-rated-3-5 .star-4:before,
// .product-rated-4-5 .star-5:before {
// 	color: @star-rated-color;
// 	content: '\2605'; // Full star in UTF-8
// 	position: absolute;
// 	left: 0;
// 	width: 50%;
// 	overflow: hidden;
// }

// .star-icon {
// 	position: relative;
// 	float: right;

// 	color: @star-default-color;
// 	font-family: @font-family-clean;

// 		// For IE, prevent awful aliasing at sizes below 21px by explicitly defining a font with a better glyph (that not all other systems/browsers have)
// 		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
// 			font-family: "Arial Unicode MS", Arial, Helvetica, Freesans, Helmet, clean, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// 			font-size: (@star-size * 1.2);
// 			letter-spacing: 1px;
// 		}
// 		@supports (-ms-accelerator:true) {
// 			font-family: "Arial Unicode MS", Arial, Helvetica, Freesans, Helmet, clean, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// 			font-size: (@star-size * 1.2);
// 			letter-spacing: 1px;
// 		}
// 	font-size: @star-size;
// 	text-shadow: 0 0 2px rgba(0,0,0,0);
// 	text-decoration: none !important;

// 	.transition (all .17s ease-out);

// 	&:before {
// 		.transition (all .23s ease-out);
// 	}

// 	// If hovering a star or star ahead of a half-star, fade away the half-star
// 	&.for-rating:hover:before,
// 	&.for-rating:hover ~ .star-icon:before,
// 	&.for-rating:focus:before,
// 	&.for-rating:focus ~ .star-icon:before {
// 		opacity: 0;
// 	}

// 	// VARIATION: FOR RATING
// 	&.for-rating {

// 		&:hover,
// 		&:hover ~ .for-rating,
// 		&:focus,
// 		&:focus ~ .for-rating {
// 			color: @star-hover-color;
// 			text-shadow: 0 0 2px rgba(0,0,0,0.7);
// 			outline: 0; // since we make the changes above, no need for focus outline
// 		}
// 		&:focus {
// 			.animation (pulse 1.25s ease 0 1 normal both);
// 		}
// 	}
// }

// // Pretty sure this no longer applies -- KB 2016-01-20
// // // Firefox Hack :(
// // @-moz-document url-prefix() {
// // 	.star-icon {
// // 		// font-size: 50px;
// // 		// line-height: 34px;
// // 		font-size: (@star-size * 1.6470588235);
// // 		line-height: 1;
// // 	}
// // }

// // Attempting a "star-by-star" animation effect once
// // ...but currently pretty messy. Lots of weird behavior. Needs some rethinking.
// // ---------------------------------------
// .product-rating {
// 	// @vars ---------------------
// 	   @animation-base-speed: .055s;
// 	// @end vars -----------------

// 	.star-icon:nth-child(4) { .transition-delay (@animation-base-speed * 1); }
// 	.star-icon:nth-child(3) { .transition-delay (@animation-base-speed * 2); }
// 	.star-icon:nth-child(2) { .transition-delay (@animation-base-speed * 3); }
// 	.star-icon:nth-child(1) { .transition-delay (@animation-base-speed * 4); }
// 	// Cancel this animation for the hovered stars
// 	&:hover .star-icon,
// 	&:focus .star-icon {
// 		.transition-delay(0) !important;
// 	}
// }


// Accordions
// ---------------------------------------

// Variables
// --------------------------
// Outer Accordion _________
@accordion-side-gutters: 			0;
@accordion-margin: 					0 auto (@common-gutter / 2);
@accordion-panel-separators: 		1px solid @line-divider-color;
@accordion-outer-border-top: 		0;
@accordion-outer-border-bottom: 	0;

@accordion-bg: 						transparent;

@accordion-font-size: 				1em;

// Panel Titles _________
@accordion-panel-title-padding: 	@common-gutter @accordion-side-gutters;
@accordion-panel-title-margin: 		0;
@accordion-panel-title-font-family:	@font-family-base;
@accordion-panel-title-font-size: 	1.5em;
@accordion-panel-title-line-height:	1.1em;

// Panel Title anchors _________
@accordion-trigger-color: 			@link-color;
@accordion-trigger-color--hover: 	@link-highlight;
@accordion-trigger-bg: 				transparent;
@accordion-trigger-bg--hover: 		transparent;
@accordion-trigger-text-decoration:	none !important;
@accordion-trigger-icon-opened: 	"–";
@accordion-trigger-icon-closed: 	"+";
@accordion-trigger-icon-font-size: 	1em;
@accordion-trigger-icon-color: 		inherit;

// Panel Body _________
@accordion-panel-body-top-gutter: 	0;
@accordion-panel-body-side-gutters: @accordion-side-gutters;
@accordion-panel-body-bg: 			#FFF;
// --------------------------


// Elements
// --------------------------
.accordion {
	margin: @accordion-margin;
	border-top: @accordion-outer-border-top;
	border-bottom: @accordion-outer-border-bottom;

	background: @accordion-bg;
	font-size: @accordion-font-size;

	.panel {
		//
		border-top: @accordion-panel-separators;

		&:first-child {
			border-top: 0;
		}
	}
	.panel-title {
		margin: @accordion-panel-title-margin;

		font-family: @accordion-panel-title-font-family;
		font-size: @accordion-panel-title-font-size;
		line-height: @accordion-panel-title-line-height;
	}
	.panel-body {
		.prevent-bottom-margin-collapse();

		padding: @accordion-panel-body-top-gutter @accordion-panel-body-side-gutters 0;

		background: @accordion-panel-body-bg;
	}
}
.accordion-trigger {
	position: relative;
	display: block;

	padding: @accordion-panel-title-padding;

	background: @accordion-trigger-bg;

	text-decoration: @accordion-trigger-text-decoration;
	color: @accordion-trigger-color;

	// States
	&:hover {
		background: @accordion-trigger-bg--hover;

		color: @accordion-trigger-color--hover;
	}

	// icon: minus sign
	&:before {
		content: @accordion-trigger-icon-opened;
		// font-family: @font-family-iconfont !important;
		speak: none;

		float: right;
		padding-left: (@common-gutter / 2);

		font-size: @accordion-trigger-icon-font-size;
		color: @accordion-trigger-icon-color;
	}
	// icon: plus sign when collapsed
	&.collapsed:before {
		content: @accordion-trigger-icon-closed; //"\e626";
	}
}



// Loading Spinner
// ---------------------------------------

// Variables
// --------------------------
@loading-spinner-size: 			30px;
@loading-spinner-line-width: 	8px;
@loading-spinner-color: 		#000;
@loading-spinner-frequency: 	0.75s;
// --------------------------

// Elements
// --------------------------
.loading-spinner {
	display: inline-block;
	width:  @loading-spinner-size;
	height: @loading-spinner-size;
	border: @loading-spinner-line-width solid @loading-spinner-color;
	border-right-color: transparent;
	border-radius: 50%;

	.animation(full-rotation @loading-spinner-frequency linear infinite);
}
