//
// Core WMU / WorldCraft styles
// --------------------------------------------------



// Grid changes
// ---------------------------------------


// XXS size specific styling/layout adjustments
// --------------------------
@media (max-width: @screen-xxs-max) {
	.col-xxs-12 {
		width: 100%;
	}
}



//
// Elements
// --------------------------------------------------

// a img {
// 	-webkit-backface-visibility: hidden;
// 	        backface-visibility: hidden;
// }

figcaption {
	padding: 0 1em;
}
