//
// Modal plugin default styles
// --------------------------------------------------

// .modal-open {
// 	overflow: hidden;
// }
// body.modal-open,
// .modal-open .navbar-fixed-top,
// .modal-open .navbar-fixed-bottom {
// 	margin-right: 15px;
// }
.modal {
	display: none;
	overflow: auto;
	overflow-y: scroll;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
}
.modal.fade .modal-dialog {
	-webkit-transform: translate(0, -25%);
	    -ms-transform: translate(0, -25%);
	        transform: translate(0, -25%);
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	    -ms-transform: translate(0, 0);
	        transform: translate(0, 0);
}
.modal-dialog {
	margin-left: auto;
	margin-right: auto;
	width: auto;
	padding: 10px;
	z-index: 1050;
}
.modal-content {
	position: relative;
	background-color: #ffffff;
	border: 1px solid #999999;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	background-clip: padding-box;
	outline: none;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
	background-color: #000000;
}
.modal-backdrop.fade {
	opacity: 0;
}
.modal-backdrop.in {
	opacity: 0.5;
}
.modal-header {
	padding: 15px;
	border-bottom: 1px solid #e5e5e5;
	min-height: 16.428571429px;
}
.modal-header .close {
	margin-top: -2px;
}
.modal-title {
	margin: 0;
	line-height: 1.428571429;
}
.modal-body {
	position: relative;
	padding: 20px;
}
.modal-footer {
	margin-top: 15px;
	padding: 19px 20px 20px;
	text-align: right;
	border-top: 1px solid #e5e5e5;
}
.modal-footer:after {
	content: "";
	display: block;
	clear: both;
}
.modal-footer .button + .button {
	margin-left: 5px;
	margin-bottom: 0;
}
// .modal-footer .button-group .button + .button {
// 	margin-left: -1px;
// }
// .modal-footer .button-block + .button-block {
// 	margin-left: 0;
// }
@media screen and (min-width: 768px) {
	.modal-dialog {
		left: 50%;
		right: auto;
		width: 600px;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.modal-content {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	}
}
