//
// Layout - Footer
// --------------------------------------------------

//
// Layout - Footer
// --------------------------------------------------
.global-footer {
	padding-top: 0;
	padding-bottom: 0;
	margin-top: @content-vertical-spacing;

	background-color: transparent;
	color: @global-text-color;
	overflow: hidden;

	@media (min-width: @site-max-width) {
		margin-top:    @content-vertical-spacing-locked;
	}
}


// Footer Body
// ---------------------------------------
.footer-body {
	padding-top:    (@common-gutter * 1.5);
	padding-bottom: (@common-gutter / 2);

	background: @footer-bg-color;
	background: linear-gradient(to top, #FFF 0%, @footer-bg-color 100%);
	background: linear-gradient(to bottom, lighten(@footer-bg-color, 1%) 0%, darken(@footer-bg-color, 8%) 100%);

	font-weight: lighter;
}

.global-logo-footer {
	display: block;
	margin-bottom: (@common-gutter);

	> .global-logo-image {
		display: block;
		height: auto;
		width: 280px;
	}
	&:hover {

		> .global-logo-image {
			opacity: 1;
			background-color: #FFF;
			.box-shadow(0 0 0 5px #FFF);
		}
	}
}

.footer-address {
	font-size: 0.77777em;
	line-height: 1.35;
	font-style: normal;
	font-weight: normal;
}

.footer-heading {
	margin-bottom: 0.5em;

	font-family: @font-family-sans;
	letter-spacing: 0.025em;
	color: @secondary-site-color;
}

.footer-nav-list {
	margin-top: 0;

	padding-left: 0; //(@common-gutter / 2);

	font-size: 0.88888em;
	font-weight: normal;
	color: @secondary-site-color; // Colorize the bullets
	list-style: none;
}

.social-icon-link.facebook {
	top: -3px;
	position: relative;

	font-size: 37px;
}
// .social-icon-link.wordpress {
// 	color: #f00 !important;
// }

.security-link {
	display: block;
	margin-top: (@common-gutter / 2);
	margin-bottom: 1em;

	font-size: 0.77777em;
	font-weight: bold;
	letter-spacing: 0.025em;
	color: #DCA900;
	text-shadow: 0 0 1em fade(#FFF, 75%), 0 1px 0 fade(#FFF, 75%);

	.star-icon {
		font-size: 1.25em;
		line-height: 1;
	}
}

// Social icon listing
// --------------------------
// .social-icons {
// 	.clear_float();

// 	margin-bottom: (@common-gutter / 4);

// 	> li {
// 		margin-left: 5px;
// 		&:first-child { margin-left: 0; }
// 	}
// }
.social-icon-link {
	//

	&.mail {
		width: 32px;
		height: 32px;
		line-height: 32px;
		border-radius: 5px;

		background: @wc-green;
		text-align: center;

		&:before {
			display: inline-block;
			vertical-align: top;
			font-size: 22px;
		}
	}
}


// Footer bottom
// ---------------------------------------
.footer-bottom {
	background: @wc-green;
	text-align: center;
	font-size: 0.85em;
	letter-spacing: 0.02em;
	color: #FFF;
	text-shadow: 0 0 .5em fade(#000, 30%);

	.container {
		padding-top:    (@common-gutter / 2);
		padding-bottom: (@common-gutter / 2);
	}
}


// // Layout change
// // --------------------------
// @media (min-width: @screen-lg-min) {
// 	//
// }
